<template>
  <div>
    <b-table
      empty-text="Não existe registros para serem apresentados na lista."
      :items="list"
      :fields="fields"
      responsive
      show-empty
      small
      striped
    >
      <template #head(actions)>
        <b-button
          id="btnAdd"
          @click.prevent="add()"
          v-has-permission.permission="['DIO', 'CA']"
          block
          size="sm"
          variant="success"
          >Adicionar</b-button
        >
      </template>
      <template #cell(dioModel)="row">
        {{ row.item.dioModel.name }}
      </template>
      <template #cell(actions)="row">
        <b-button
          :id="'btnEdit' + row.item.id"
          title="Editar"
          @click.prevent="edit(row.item.id)"
          v-has-permission.permission="['DIO', 'AL']"
          variant="warning"
          size="sm"
        >
          <i class="fa fa-edit"></i>
        </b-button>

        <b-button
          :id="'btnDelete' + row.item.id"
          title="Excluir"
          @click.prevent="remove(row.item.id, row.index)"
          v-has-permission.permission="['DIO', 'EX']"
          size="sm"
          variant="danger"
        >
          <i class="fa fa-trash-o"></i>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import TabsBase from "./TabsBase";
import { dioPath } from "../../../../router/ApiPaths";

export default {
  name: "DioTab",
  extends: TabsBase,
  data() {
    return {
      fields: [],
    };
  },
  mounted() {
    this.type = 1;
    this.path = dioPath;
    this.load();
    this.fields = [
      {
        key: "name",
        label: "Nome",
      },
      {
        key: "hack",
        label: "Rack",
      },
      {
        key: "dioModel",
        label: "Modelo",
      },
      {
        key: "actions",
        label: "Ações",
        class: "text-right width-100",
      },
    ];
  },
};
</script>
