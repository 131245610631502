import { tokenStore } from "../components/extra/LocalStorage";
import router from '../router'
import store from "../store/";
import { getLogout } from "@/components/MessageBus";

export const performLogout = () => {

  getLogout(()=>{
      store.dispatch("logout");
      tokenStore.set(null);
      store.dispatch("setFolder", null);
      store.dispatch("setFolderSecundary", null);
      router.go("/login");
  })
};


