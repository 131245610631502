import Vue from "vue";
import Router from "vue-router";
import numeral from "numeral";
import moment from "moment";
import money from "v-money";
import Photoswipe from "vue-pswipe";
import VueLazyload from 'vue-lazyload';

import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from "bootstrap-vue";

import router from "./router";
import store from "./store";
import { hasPermissionDirective } from "./components/extra/hasPermission";
import App from "./App";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { performLogout } from "./request/logoutUtil";


window.$ = window.jQuery = require("jquery");

Vue.use(Router);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(BootstrapVueIcons);


Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'dist/error.png',
  loading: 'dist/loading.gif',
  attempt: 1
});

Vue.use(money, {
  precision: 2,
});

Vue.config.productionTip = false;

Vue.use(Photoswipe);

Vue.directive("has-permission", hasPermissionDirective);

Vue.filter("formatNumberZero", function (value) {
  if (value > -1) {
    return numeral(value).format("0.00");
  } else {
    return value;
  }
});

Vue.filter("formatNumber", function (value) {
  if (value > -1) {
    return numeral(value).format("0");
  } else {
    return value;
  }
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment.utc(value).format("DD/MM/YYYY");
  } else {
    return value;
  }
});

Vue.filter("formatDateTime", function (value) {
  if (value) {
    return moment(value).format("DD/MM/YYYY HH:mm");
  } else {
    return value;
  }
});

Vue.filter("formatTimestamp", function (value) {
  if (value) {
    return moment(value).format("DD/MM/YYYY HH:mm:ss");
  }
});

Vue.filter("formatTimestampShort", function (value) {
  if (value) {
    return moment(value).format("DD/MM/YY HH:mm:ss");
  }
});

Vue.directive("focus", {
  inserted: function (el) {
    el.focus();
  },
});

Vue.directive("max-value", {
  bind(el, binding, vnode) {
    el.addEventListener("input", () => {
      const currentValue = Number(el.value);
      const maxValue = binding.value;
      if (currentValue > maxValue) {
        vnode.componentInstance.$emit("input", maxValue);
      }
    });
  },
});

/* eslint-disable no-new */
window.vue = new Vue({
  store,
  el: "#app",
  router,
  components: {
    App,
  },
  template: "<App/>",
  mounted() {
    var vm = this;
    window.addEventListener("keyup", function (evt) {
      // console.log('arrow up listener', evt.keyCode)

      const alt = evt.metaKey || evt.altKey;
      const shift = evt.shiftKey;
      if (!store.getters.isDisableHotKeys) {
        if (!alt && evt.keyCode == 27) {
          // console.log('ESC ')
          vm.$emit("esc");
        } else if (alt && evt.keyCode == 80) {
          vm.$emit("open_folder");
        } else if (alt && evt.keyCode == 65) {
          vm.$emit("open_door");
        } else if (shift && evt.keyCode >= 48 && evt.keyCode <= 57) {
          vm.$emit("tool", evt.keyCode);
        } else if (shift && evt.keyCode == 83) {
          vm.$emit("tool", "s");
        } else if (shift && evt.keyCode == 77) {
          vm.$emit("tool", "m");
        }
      }
    });
    performLogout();
    
    document.addEventListener("visibilitychange", this.changeVisibility);
    window.addEventListener("blur", this.sendLostFocus);
    window.addEventListener("focus", this.sendInFocus);
  },
  methods: {
    changeVisibility(hidden) {
      if (hidden) {
        this.sendLostFocus();
      } else {
        this.sendInFocus();
      }
    },
    sendLostFocus() {
      this.$emit("LOSTFOCUS", "");
    },
    sendInFocus() {
      this.$emit("INFOCUS", "");
    },
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.changeVisibility);
    window.removeEventListener("blur", this.sendLostFocus);
    window.removeEventListener("focus", this.sendInFocus);
  }
});
