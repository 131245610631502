<template>
  <img :src="selectedImage" />
</template>

<script>
export default {
  name: "MapElementImg",
  props: ["type"],
  computed: {
    selectedImage() {
      if (this.type === "CD" || this.type === "CC") {
        return require("@/assets/img/map/cable.png");
      } else if (this.type === "P") {
        return require("@/assets/img/map/pop_outline.png");
      } else if (this.type === "C") {
        return require("@/assets/img/map/amendment_outline.png");
      } else if (this.type === "O") {
        return require("@/assets/img/map/cto_outline.png");
      } else if (this.type === "S") {
        return require("@/assets/img/map/user_green_outline.png");
      } else if (this.type === "U") {
        return require("@/assets/img/map/hexagon.png");
      } else if (this.type === "T") {
        return require("@/assets/img/map/technicalReserve_outline.png");
      } else if (this.type === "E") {
        return require("@/assets/img/map/pole_outline.png");
      } else if (this.type === "SP") {
        return require("@/assets/img/map/chat_outline.png");
      } else if (this.type === "CV") {
        return require("@/assets/img/map/circuit_outline.png");
      } else if (this.type === "CL") {
        return require("@/assets/img/map/cordoalha_outline.png");
      } else if (this.type === "MP") {
        return require("@/assets/img/repair_outline.svg");
      } else if (this.type === "V") {
        return require("@/assets/img/map/building_outline.png");
      } else if (this.type === "EL") {
        return require("@/assets/img/map/hexagon.png");
      }
      return null;
    },
  },
};
</script>
