<template>
  <div transition="fade" v-if="components.length">
    <div
      class="overlay"
      v-for="modal in components"
      :key="modal.props.componentId"
    >
      <transition name="fade">
        <div class="modal">
          <div
            class="modal-content modal-dialog modal-lg"
            :class="{
              'modal-dialog-full': modal.props.fullScreen,
              'modal-dialog-middle': modal.props.middle,
            }"
          >
            <div>
              <component :is="modal.component" v-bind="modal.props" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { newPopup, closePopup, messageBus, closeAllPopup } from "./MessageBus";

export default {
  name: "PopupContainer",
  data() {
    return {
      components: [],
    };
  },
  methods: {
    addComponent(payload) {
      const exists = this.components.find(
        (element) => payload.component.name == element.component.name
      );
      if (!exists) {
        this.components.push(payload);
      }
    },
    closeComponent() {
      this.components.pop();
    },
  },
  mounted() {
    messageBus.$off(newPopup);
    messageBus.$on(newPopup, (payload) => {
      this.addComponent(payload);
    });
    messageBus.$on(closePopup, () => {
      this.closeComponent();
    });
    messageBus.$on(closeAllPopup, () => {
      while (this.components.length > 0) {
        this.closeComponent();
      }
    });
  },
};
</script>

<style lang="sass">
@import "../../public/static/sass/_variables.scss"

.overlay
  position: fixed
  width: 100%
  height: 100%
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: $color-black-50
  z-index: 1005

.fade-enter-active, .fade-leave-active
  transition: opacity 0.25s ease-out

.fade-enter, .fade-leave-to
  opacity: 0

.blur
  filter: blur(1px)
  opacity: 0.4

.modal
  display: block !important

.modal-dialog-full
  width: 100%
  height: 100%
  padding: 0
  margin-top: 0 !important
  min-width: 100%
  min-height: 100%
  margin: 0 !important

.modal-content-full
  height: 100%
  border-radius: 0

.modal-dialog-middle
  max-width: 70% !important

@media only screen and (max-width: 992px)
  .modal-dialog
    max-width: 800px !important
</style>
