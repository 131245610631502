<template>
  <div>
    <ul class="list-group list-group-flush" v-if="list.sn">
      <li class="list-group-item">
        <label>OLT:</label>
        {{ list.olt ? list.olt.name + " (" + list.olt.ip + ")" : "" }}
      </li>
      <li class="list-group-item">
        <span class="title-label">SN:</span>
        <span>{{ list.sn }}</span>
      </li>
      <li class="list-group-item">
        <span class="title-label">Descrição:</span>
        {{ list.description }}
      </li>
      <li class="list-group-item">
        <span class="title-label">
          FSP:
          <i
            class="fa fa-question-circle icon-question"
            aria-hidden="true"
            title="Chassi, placa e porta."
          ></i>
        </span>
        {{ list.fsp }}
      </li>
      <li class="list-group-item">
        <span class="title-label">Estado:</span>
        {{ list.state }}
      </li>
      <li class="list-group-item">
        <span class="title-label">Data da ultima atualização:</span>
        {{ list.updated | formatDateTime }}
      </li>
      <div class="pt-3">
        <b-button
          id="btnRemoveSn"
          variant="danger"
          v-has-permission.permission="['OLT', 'EX']"
          @click="removeSN"
          title="Desvincular SN"
        >
          <span>Desvincular SN</span>
        </b-button>
      </div>
    </ul>
    <div v-if="!list.sn">
      <b-form-group label="OLT:">
        <b-form-select
          :class="{ 'is-invalid': missingOlt }"
          v-model="entity.oltId"
          @change="loadSN(entity.oltId)"
          size="sm"
        >
          <b-form-select-option
            v-for="entity in oltList"
            :value="entity.id"
            :key="entity.id"
          >
            {{ entity.name + " - " + entity.ip }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <div class="form-group">
        <label>SN:</label>
        <ComboAutocomplete
          :listCombo="SNList"
          :fieldSearch="'sn'"
          :missingInputExpression="missingSN"
          v-on:inputChange="setSN"
          :isDisabled="missingOlt"
          :maxlength="maxlength"
        />
      </div>
      <div class="pt-3">
        <b-button
          variant="success"
          v-has-permission.permission="['OLT', 'AL']"
          @click="save"
          :disabled="saveDisabled"
          size="sm"
        >
          Vincular SN
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseRequest from "../../../../request/request";
import {
  oltDataEquipmentPath,
  oltDataClientPath,
  oltDirectPath,
  oltDataPath,
} from "../../../../router/ApiPaths";
import { sucessMessage } from "../../../../components/MessageBus";
import ComboAutocomplete from "../../../../components/extra/ComboAutocomplete";

export default {
  name: "OltData",
  mixins: [BaseRequest],
  props: ["equipmentId", "updateMap"],
  components: { ComboAutocomplete },
  data() {
    return {
      list: {},
      entity: {},
      oltList: [],
      SNList: [],
      isNewSN: true,
      missingSN: true,
      maxlength: 20,
    };
  },
  computed: {
    missingOlt: function () {
      return !this.entity.oltId;
    },
    saveDisabled: function () {
      return this.missingOlt || this.missingSN;
    },
  },
  mounted() {
    this.loadOltData();

    this.baseRequest("get", oltDirectPath).then((data) => {
      this.oltList = data;
    });
  },
  methods: {
    setSN(ev) {
      if (ev.id) {
        this.entity.sn = ev.id;
        this.isNewSN = false;
      } else {
        this.entity.sn = ev.sn;
        this.isNewSN = true;
      }
      if (this.entity.sn && this.entity.sn != "") this.missingSN = false;
      else this.missingSN = true;
    },
    loadOltData() {
      let path = oltDataEquipmentPath + "/" + this.equipmentId;

      this.baseRequest("get", path).then((data) => {
        if (data) {
          this.list = data;
        }
      });
    },
    loadSN(oltId) {
      this.baseRequest("get", oltDataPath(oltId) + "/free").then((data) => {
        this.SNList = data;
      });
    },
    changeModeSN() {
      this.writeSN = !this.writeSN;
    },
    save() {
      let method;
      let entitySave;
      let pathSave;

      if (this.isNewSN) {
        method = "post";
        entitySave = this.entity;
        pathSave = oltDataEquipmentPath + "/" + this.equipmentId;
      } else {
        method = "put";
        entitySave = { equipmentId: this.equipmentId };
        pathSave = oltDataClientPath(this.entity.sn);
      }
      entitySave.sincronized = true;

      this.baseRequest(method, pathSave, entitySave).then((data) => {
        sucessMessage("Salvo com sucesso!");
        this.loadOltData();
        if (this.updateMap) {
          this.updateMap(data);
        }
      });
    },
    removeSN() {
      this.baseRequest("put", oltDataClientPath(this.list.id), {
        equipmentId: null,
        sincronized: false,
      }).then((data) => {
        this.list = [];
        sucessMessage("Desvinculado com sucesso!");
        if (this.updateMap) {
          this.updateMap(data);
        }
      });
    },
  },
};
</script>


<style lang="sass">
@import "../../../../../public/static/sass/_variables.scss"

// $color-white: #ffffff !default
// $brand-color-primary: #ff5800 !default

.btn_save_sn
  background-color: $brand-color-primary
  border: none
  border-radius: 5px
  color: $color-white!important

.btn_modesn
  border-color: #9e9e9e!important
</style>
