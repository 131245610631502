const loopArray = (array, text) => {
	for (let i = 0; i < array.length; i++) {
		array[i].style.display = text
	}
}

export const hideElementsByClass = (name) => {
	const elements = document.getElementsByClassName(name)
	loopArray(elements, 'none')
}

export const showElementsByClass = (name) => {
	const elements = document.getElementsByClassName(name)
	loopArray(elements, 'block')
}