<template>
  <div id="ResizeModal" style="display:none">
    <div class="alert-hideModals alert-warning" @click.prevent="showModals()">
      <h6>Clique para voltar exibir as telas.</h6>
    </div>
  </div>
</template>

<script>
import { showAll } from "../../components/extra/HideModals";
export default {
  name: "ResizeModal",
  methods: {
    showModals() {
      showAll();
    }
  }
};
</script>

<style scoped>
.alert-hideModals {
  text-align: center;
  position: fixed;
  top: 5px;
  z-index: 10002;
  width: 40%;
  right: 5px;
  padding-left: 10px;
  padding-bottom: 30px;
  padding-right: 30px;
  padding-top: 30px;
  cursor: pointer;
}
</style>
