<template>
  <!-- <section style="display: flex; flex-direction: column" class="mr-3">
    <b-nav-text class="py-0 user-limit text-light">{{ getUser.name }}</b-nav-text>
    <p class="py-0 version mb-0 text-light">${MAP-VERSION-MAP}</p>
  </section> -->
   <div>
      <div class="d-flex flex-column">
        <div class="py-0 user-limit text-light">{{ getUser.name }}
        </div>
        <div>
          <div class="d-flex flex-row justify-content-end align-items-center">
            <div>
              <SocketOnOff class="pr-1 onoff-size"/>
            </div>
            <div>
              <p class="py-0 version mb-0 text-light">${MAP-VERSION-MAP}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SocketOnOff from "./SocketOnOff";

export default {
  name: "LoggedUser",
  components: {
    SocketOnOff
  },
  computed: {
    ...mapGetters({
      getUser: "getUser",
    }),
  },
};
</script>


<style scoped>
.version {
  font-size: 10px;
  text-align: end;
  color: rgba(255, 255, 255, 0.5);
}

.onoff-size {
  font-size: 10px; /* Exemplo para ajustar o tamanho do ícone */
}

@media only screen and (max-width: 770px) {
  .user-limit {
    display: none;
    max-width: 12ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
