<template>
  <div class="tab-pane" v-show="isActive">
    <!-- <slot v-if="isMounted || isActive"></slot> -->
    <slot v-if="isActive"></slot>
  </div>
</template>
<script>
export default {
  name: "tabBase",
  props: {
    title: {
      required: true,
    },
    selected: {
      default: false,
    },
    permission: {
      default: null,
    },
  },
  data() {
    return {
      isActive: false,
      // isMounted: false
    };
  },
  computed: {
    href() {
      return "#" + this.title.toLowerCase().replace(/ /g, "-");
    },
  },
  mounted() {
    this.isActive = this.selected;
  },
};
</script>

<style>
.tab-pane {
  padding-top: 2px;
}
</style>