export default {
	saveMapCenter: (mapCenter) => {
		localStorage.setItem("mapCenter", JSON.stringify(mapCenter));
	},
	getMapCenter: () => {
		return JSON.parse(localStorage.getItem("mapCenter"));
	},
  saveBbox: (bbox) => {
		localStorage.setItem("bbox", JSON.stringify(bbox));
	},
	getBbox: () => {
		return JSON.parse(localStorage.getItem("bbox"));
	},
	saveMapZoom: function (zoom) {
		localStorage.setItem("zoom", zoom);
	},
	getMapZoom: function () {
		return localStorage.getItem("zoom");
	},
	setLayerState: function (layer, state) {
		localStorage.setItem(layer, state);
	},
	getLayerState: function (layer) {
		return localStorage.getItem(layer);
	},
	setMapBase: function (layer) {
		localStorage.setItem('mapBase', layer);
	},
	getMapBase: function () {
		let baseMap = localStorage.getItem('mapBase');
		if (!baseMap) {
			baseMap = 1
		}
		return baseMap
	},
	setImporterList: function (importer) {
		localStorage.setItem('importerList', importer);
	},
	getImporterList: function () {
		return localStorage.getItem('importerList');
	},
}

export const tokenStore = {
  get: () => {
    return localStorage.getItem("validator");
  },
  set: (token) => {
    localStorage.setItem("validator", token);
  },
};

export const folderLocalStore = {
  get: () => {
	const folders = localStorage.getItem("folders")	
    return JSON.parse(folders)
  },
  set: (list) => {
    localStorage.setItem("folders", JSON.stringify(list));
  },
};


