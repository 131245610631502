import store from '../../store'

export const hasPermission = (permission) => {
    if (permission && permission.length && store.getters.getUser && store.getters.getUser.permissions) {
        const ind = store.getters.getUser.permissions.findIndex(x => x.code === permission[0])
        let hasAction = false

        if (ind != -1) {
            for (let i = 1; i < permission.length; i++) {
                if (store.getters.getUser.permissions[ind].actions.indexOf(permission[i]) > -1) {
                    hasAction = true
                }
            }
        }
        return hasAction
    }
    return false
}

export const hasPermissionDirective = {
    inserted: function (el, binding, vnode) {
        if (!hasPermission(binding.value)) {
            vnode.elm.parentElement.removeChild(vnode.elm)
        }
    }
}