export default {
	methods: {
		isNumberDecimal: (evt) => {
			evt = (evt) ? evt : window.event;
			const charCode = (evt.which) ? evt.which : evt.keyCode;

			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		isNumber: (evt) => {
			evt = (evt) ? evt : window.event;
			const charCode = (evt.which) ? evt.which : evt.keyCode;

			if (charCode < 48 || charCode > 57) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		isNumberDecimalOrNegative: (evt) => {
			evt = evt ? evt : window.event;
			const charCode = evt.which ? evt.which : evt.keyCode;
	  
			if (
			  (charCode >= 48 && charCode <= 57) ||
			  charCode === 46 ||
			  charCode === 45
			) {
			  return true;
			} else {
			  evt.preventDefault();
			}
		  },
	}
}
