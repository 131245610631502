import printJS from "print-js";

export default (element, title, ignoreElements = []) => {
  const style =
    `
        .button-increase-width, .button-decrease-width, .button-increase-height, .button-decrease-height{
            display: none;
        }
        
        .button-tube, .button-all, .button-map{
            display: none;
        }

        .button-comments, .controls-zoom{
            display: none
        }

        .printarea {
            height: 782px 
            !important;
        } 

        .divComments{
            page-break-before: always;
        }  
        
        table{
            border-collapse: 
            collapse;width: 100%;
        }  
        
        .col_um{ 
            width:20%;
            font-weight: bold;
            text-align: center;
        } 
        
        p{
            margin: 0!important;
        } 
        
        table, td {
            border: 1px solid black;
            padding:10px
        } 
        h6 {
            font-size: 18px; 
            text-align: center;
        } 
        .connection-select {
            stroke:#ff0808 !important; 
            stroke-width: 10 !important;
        }`;

  printJS({
    printable: element,
    type: "html",
    documentTitle: "",
    scanStyles: false,
    style: style,
    css: "jointjs/dist/joint.min.css",
    header: title,
    ignoreElements: ignoreElements
  });
};
