<template>
  <div
    :class="{'text-success' :getOnOff, 'text-danger' :!getOnOff}"
    title="Indicação de conexão do browser com o servidor, vermelho esta desconectado e verde conectado"
  >
    <i class="fa fa-circle" aria-hidden="true"></i>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SocketOnOff",
  computed: {
    ...mapGetters({
      getOnOff: "getOnOff"
    })
  }
};
</script>

<style scoped></style>
