<template>
  <b-row class="justify-content-md-center">
    <b-overlay :show="eqpsLoading" rounded opacity="0.6" class="b-spinner-codemaps">
      <div class="px-3 py-2">
        <b-button-group size="sm">
          <b-button
          variant="success"
          @click="setMapPosition()"
          :class="eqp1? 'active' : 'btn-danger'"
          ><strong>Ponta A -</strong>  <span v-text="eqp1 ? eqp1.name : ''"></span></b-button
          >
          <b-button variant="light"
          ><img
          class="img-btn c-default"
          src="../../../assets/img/map/cable.png"
          /></b-button>
          <b-button
          variant="success"
          @click="setMapPosition(true)"
          :class="eqp2? 'active' : 'btn-danger'"
          ><strong>Ponta B -</strong>  <span v-text="eqp2 ? eqp2.name : ''"></span></b-button
          >
        </b-button-group>
      </div>
    </b-overlay>
  </b-row>
</template>

<script>
import { eqpEdgesPath } from "../../../router/ApiPaths";
import request from "../../../request/request";
import { minimizeAll } from "../../../components/extra/HideModals";
import { sendMapAction, centerMapPoint } from "../../../components/MessageBus";

export default {
  name: "CableEdgres", //Conferir erro na escrita
  extends: request,
  props: ["cableId", "cable", "folderId"],
  data() {
    return {
      eqp1: "",
      eqp2: "",
      eqpsLoading: false,
    };
  },
  watch: {
    cableId: function() {
      this.loadEqps();
    },
    folderId: function() {
      this.loadEqps();
    },
  },
  methods: {
    setMapPosition(end) {
      let point;

      if (!end) {
        point = {
          lat: this.cable.coordinates[0][1],
          long: this.cable.coordinates[0][0]
        };
      } else {
        point = {
          lat: this.cable.coordinates[this.cable.coordinates.length - 1][1],
          long: this.cable.coordinates[this.cable.coordinates.length - 1][0]
        };
      }

      sendMapAction(centerMapPoint, point);
      minimizeAll();
    },
    loadEqps() {
      if (this.cableId && this.folderId){
        this.eqpsLoading = true
        this.get(eqpEdgesPath(this.folderId, this.cableId)).then(data => {
        this.eqp1 = data.eqp1;
        this.eqp2 = data.eqp2;
        this.eqpsLoading = false
      });
    }
      
    },
  }
};
</script>

<style lang="sass"></style>
