<script>
export default {
  methods: {
    showConfirm(message, title, cb, cbCancel) {
      this.$confirm({
        title: title ? title : "",
        message: message ? message : "Deseja mesmo fazer isso?",
        button: {
          no: "Cancelar",
          yes: "Ok",
        },
        callback: (confirm) => {
          if (confirm && cb) cb();
          if (!confirm && cbCancel) cbCancel();
        },
      });
    },
    showAlert(message, title) {
      this.$confirm({
        title: title ? title : "",
        message: message ? message : "",
        button: {
          yes: "Ok",
        },
      });
    },
  },
};
</script>
