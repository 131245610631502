import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    logged: false,
    cableUsageLegend: false,
    clientSignalLegend: false,
    ctoUsageLegend: false,
    cardHeatMap: false,
    project: "",
    token: "",
    user: "",
    notification: 0,
    message: false,
    folder: "",
    folderSecundary: [],
    nameElementsLegend: true,
    googleKey: "",
    googleKeyClient: false,
    disableHotKeys: false,
    video: "",
    finantialPending: false,
    onoff: false,
    hasKml: false
  },
  mutations: {
    loading(state, value) {
      state.loading = value;
    },
    logged(state, value) {
      state.logged = value;
    },
    token(state, value) {
      state.token = value;
    },
    user(state, value) {
      state.user = value;
    },
    nameElements(state, value) {
      state.nameElementsLegend = value;
    },
    cableUsage(state, value) {
      state.cableUsageLegend = value;
    },
    ctoUsage(state, value) {
      state.ctoUsageLegend = value;
    },
    clientSignal(state, value) {
      state.clientSignalLegend = value;
    },
    cardHeatMap(state, value) {
      state.cardHeatMap = value;
    },
    companyId(state, value) {
      state.user.company.id = value;
    },
    project(state, value) {
      state.project = value;
    },
    notification(state, count) {
      state.notification = count;
    },
    message(state, value) {
      state.message = value;
    },
    folder(state, value) {
      state.folder = value;
    },
    googleKey(state, value) {
      state.googleKey = value;
    },
    googleKeyClient(state, value) {
      state.googleKeyClient = value;
    },
    hasKml(state, value) {
      state.hasKml = value;
    },
    folderSecundary(state, value) {
      state.folderSecundary = value;
    },
    addFolderSecundary(state, value) {
      for (let i = 0; i < state.folderSecundary.length; i++) {
        if (state.folderSecundary[i].id === value.id) {
          return;
        }
      }
      state.folderSecundary.push(value);
    },
    removeFolderSecundary(state, value) {
      for (let i = 0; i < state.folderSecundary.length; i++) {
        if (state.folderSecundary[i].id === value) {
          state.folderSecundary.splice(i, 1);
          break;
        }
      }
    },
    disableHotKeys(state, value) {
      state.disableHotKeys = value;
    },
    video(state, value) {
      state.video = value;
    },
    finantialPending(state, value) {
      state.finantialPending = value;
    },
    onoff(state, value) {
      state.onoff = value;
    }
  },
  actions: {
    startLoading() {
      this.commit("loading", true);
    },
    endLoading() {
      this.commit("loading", false);
    },
    setLogin(context, loginData) {
      context.commit("user", loginData.user);
      context.commit("token", loginData.token);
      context.commit("logged", true);
      context.commit("notification", loginData.user.hasNewEvent);
      context.commit("googleKey", loginData.user.company.googleKey);
      context.commit("googleKeyClient", loginData.user.googleKeyClient);
      context.commit("video", loginData.user.video);
      context.commit("hasKml", loginData.user.hasKml);

      if(loginData.user.finantialPending)
        context.commit("finantialPending", true);
    },
    showCableUsage() {
      this.commit("cableUsage", true);
    },
    hideCableUsage() {
      this.commit("cableUsage", false);
    },
    showCtoUsage() {
      this.commit("ctoUsage", true);
    },
    hideCtoUsage() {
      this.commit("ctoUsage", false);
    },
    showNameElements() {
      this.commit("nameElements", true);
    },
    hideNameElements() {
      this.commit("nameElements", false);
    },
    showClientSignal() {
      this.commit("clientSignal", true);
    },
    hideClientSignal() {
      this.commit("clientSignal", false);
    },
    showCardHeatMap() {
      this.commit("cardHeatMap", true);
    },
    hideCardHeatMap() {
      this.commit("cardHeatMap", false);
    },
    logout(context) {
      context.commit("user", null);
      context.commit("token", null);
      context.commit("logged", false);
    },
    setCompanyId(context, id) {
      context.commit("companyId", id);
    },
    setProject(context, value) {
      context.commit("project", value);
    },
    setFolder(context, value) {
      context.commit("folder", value);
    },
    setNotification(context, count) {
      // console.log('SET NOTIFICATION STORE')
      context.commit("notification", count);
      localStorage.setItem('notificationCount', count);
    },
    initializeNotification({ commit }) {
      const notificationCount = localStorage.getItem('notificationCount');
      const parsedCount = parseInt(notificationCount, 10);

      if (!isNaN(parsedCount)) {
        commit('notification', parsedCount);
      } else {
        commit('notification', 0);
      }
    },
    setMessage(context, value) {
      context.commit("message", value);
    },
    setFolderSecundary(context, value) {
      if(!value)
        value = []
      context.commit("folderSecundary", value);
    },
    addFolderSecundary(context, value) {
      context.commit("addFolderSecundary", value);
    },
    removeFolderSecundary(context, value) {
      context.commit("removeFolderSecundary", value);
    },
    setGoogleKey(context, value) {
      context.commit("googleKey", value);
    },
    setGoogleKeyClient(context, value) {
      context.commit("googleKeyClient", value);
    },
    setHasKml(context, value) {
      context.commit("hasKml", value);
    },
    setHotkeys(context, value) {
      context.commit("disableHotKeys", value);
    },
    setVideo(context, value) {
      context.commit("video", value);
    },
    setOn(context) {
      context.commit("onoff", true);
    },
    setOff(context) {
      context.commit("onoff", false);
    }
  },
  getters: {
    isLoading: state => {
      return state.loading;
    },
    isLogged: state => {
      return state.logged;
    },
    getToken: state => {
      return state.token;
    },
    getUser: state => {
      return state.user;
    },
    getCompanyId: state => {
      return state.user.company.id;
    },
    getCompany: state => {
      return state.user.company;
    },
    getProject: state => {
      return state.project;
    },
    getFolder: state => {
      return state.folder;
    },
    isShowCableUsage: state => {
      return state.cableUsageLegend;
    },
    isShowCtoUsage: state => {
      return state.ctoUsageLegend;
    },
    isShowNameElements: state => {
      return state.nameElementsLegend;
    },
    isShowClientSignal: state => {
      return state.clientSignalLegend;
    },
    isCardHeatMap: state => {
      return state.cardHeatMap;
    },
    hasNotification: state => {
      return state.notification;
    },
    hasMessage: state => {
      return state.message;
    },
    getFolderSecundary: state => {
      return state.folderSecundary;
    },
    getAllFolders: state => {
      return [state.folder, ...state.folderSecundary]
    },
    getAllFoldersId: state => {
      const folders = [state.folder, ...state.folderSecundary];
      return folders.map(folder => folder.id);
    },
    getGoogleKey: state => {
      return state.googleKey;
    },
    isDisableHotKeys: state => {
      return state.disableHotKeys;
    },
    isGoogleKeyClient: state => {
      return state.googleKeyClient;
    },
    hasKml: state => {
      return state.hasKml;
    },
    getVideo: state => {
      return state.video;
    },
    isFinantialPending: state => {
      return state.finantialPending;
    },
    getOnOff: state => {
      return state.onoff;
    }
  }
});
