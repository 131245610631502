<template>
  <b-sidebar id="sidebar-right" aria-labelledby="sidebar-no-header-title" text-variant="light" bg-variant="codemaps" right
    backdrop shadow no-header class="sidebar-main">
    <template>
      <div class="p-3">
        <b-nav-item>
          <router-link id="linkFaq" to="/faq" class="a-sidebar">
            Central de ajuda
          </router-link>
        </b-nav-item>

        <b-nav-item v-has-permission.permission="['EVT', 'VI']">
          <router-link id="linkNotification" class="a-sidebar" to="/notification">
            Notificações</router-link>
        </b-nav-item>

        <span v-has-permission.permission="['PAG', 'VI']">
          <div class="dropdown-divider _divider-menu"></div>
          <b-nav-item>
            <router-link id="linkPayments" class="a-sidebar" to="/payments">
              Pagamentos</router-link>
          </b-nav-item>
        </span>
        <span v-has-permission.permission="['AVA', 'VI']" class="m-w20 mb-3">
          <b-nav-item>
            <router-link id="linkViability" class="a-sidebar" to="/viability">
              Viabilidade</router-link>
          </b-nav-item>
        </span>
        <span v-has-permission.permission="['ATI', 'VI']" class="m-w20 mb-3">
          <b-nav-item>
            <router-link id="linkActivation" class="a-sidebar" to="/activation">
              Ativação</router-link>
          </b-nav-item>
        </span>
        <span v-has-permission.permission="['OLT', 'VI']" class="m-w20 mb-3">
          <b-nav-item>
            <router-link id="linkOlts" class="a-sidebar" to="/oltModule">
              OLT</router-link>
          </b-nav-item>
        </span>
        <span v-has-permission.permission="['LOG', 'VI']" class="m-w20 mb-3">
          <b-nav-item>
            <router-link id="linkLogging" class="a-sidebar" to="/logging">
              Auditoria</router-link>
          </b-nav-item>
        </span>

        <span v-has-permission.permission="['AMA', 'VI']">
          <div class="dropdown-divider _divider-menu"></div>
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1" id="linkMapa">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button id="btnMap" block v-b-toggle.accordion-1 @click="visible = !visible" variant="transparent"
                  class="d-flex justify-content-between sidebar-button px-0">
                  <span>Mapa</span>
                    <i v-if="visible" class="fa fa-chevron-down" style="color: #fff;" aria-hidden="true"></i>
                    <i v-else class="fa fa-chevron-up" style="color: #fff;" aria-hidden="true"></i>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <!-- <div class="dropdown-divider _divider-menu"></div> -->
                    <b-nav-item v-has-permission.permission="['CFB', 'VI']">
                      <router-link id="linkManufacturer" class="a-sidebar" to="/manufacturer">
                        Fabricante
                      </router-link>
                    </b-nav-item>
                    <b-nav-item v-has-permission.permission="['CMB', 'VI']">
                      <router-link id="linkCableModel" class="a-sidebar" to="/cablemodel">
                        Modelo de cabo</router-link>
                    </b-nav-item>
                    <b-nav-item v-has-permission.permission="['CMC', 'VI']">
                      <router-link id="linkConectorModel" class="a-sidebar" to="/conectormodel">
                        Modelo de conector</router-link>
                    </b-nav-item>
                    <b-nav-item v-has-permission.permission="['CMS', 'VI']">
                      <router-link id="linkSplitterModel" class="a-sidebar" to="/splittermodel">
                        Modelo de splitter</router-link>
                    </b-nav-item>
                    <b-nav-item v-has-permission.permission="['SMO', 'VI']">
                      <router-link
                        id="linkSwitchModel"
                        class="a-sidebar"
                        to="/switchmodel"
                      >
                        Modelo de switch</router-link
                      >
                    </b-nav-item>

                    <b-nav-item v-has-permission.permission="['CME', 'VI']">
                      <router-link id="linkAmendmentModel" class="a-sidebar" to="/amendmentmodel">
                        Modelo de caixa de emendas</router-link>
                    </b-nav-item>
                    <b-nav-item v-has-permission.permission="['CMT', 'VI']">
                      <router-link id="linkCtoModel" class="a-sidebar" to="/ctomodel">
                        Modelo de CTO</router-link>
                    </b-nav-item>
                    <b-nav-item v-has-permission.permission="['CMO', 'VI']">
                      <router-link id="linkOltModel" class="a-sidebar" to="/oltmodel">
                        Modelo de OLT</router-link>
                    </b-nav-item>
                    <b-nav-item v-has-permission.permission="['CMP', 'VI']">
                      <router-link id="linkBoardModel" class="a-sidebar" to="/boardmodel">
                        Modelo de placa</router-link>
                    </b-nav-item>
                    <b-nav-item v-has-permission.permission="['CMD', 'VI']">
                      <router-link id="linkDioModel" class="a-sidebar" to="/diomodel">
                        Modelo de DIO</router-link>
                    </b-nav-item>
                    <b-nav-item v-has-permission.permission="['CML', 'VI']">
                      <router-link id="linkCordoalhaModel" class="a-sidebar" to="/cordoalhamodel">
                        Modelo de cordoalha</router-link>
                    </b-nav-item>
                    <div class="dropdown-divider _divider-menu" v-has-permission.permission="['CKF', 'VI']"></div>
                    <b-nav-item v-has-permission.permission="['CKF', 'VI']">
                      <router-link id="linkHardwareKit" class="a-sidebar" to="/hardwarekit">
                        Kits de ferragens</router-link>
                    </b-nav-item>
                    <b-nav-item v-has-permission.permission="['CKF', 'VI']">
                      <router-link id="linkHardwareItems" class="a-sidebar" to="/hardwareitems">
                        Itens de ferragens</router-link>
                    </b-nav-item>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </span>

        <span v-has-permission.permission="['AMA', 'VI']">
          <div class="dropdown-divider _divider-menu"></div>

          <b-nav-item v-has-permission.permission="['COE', 'VI']">
            <router-link id="linkConfigurationCompany" class="a-sidebar" to="/configurationcompany">
              Configurações</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link id="linkQrcodehost" class="a-sidebar" to="/qrcodehost">
              QR code</router-link>
          </b-nav-item>
          <b-nav-item v-has-permission.permission="['VSN', 'VI']">
            <router-link id="linkSnbind" class="a-sidebar" to="/snbind">
              Vincular SN</router-link>
          </b-nav-item>
        </span>
        <div class="dropdown-divider _divider-menu"></div>
        <b-nav-item>
          <router-link id="linkChangepassword" class="a-sidebar" to="/changepassword">
            Alterar senha do usuário</router-link>
        </b-nav-item>
        <b-nav-item v-has-permission.permission="['USU', 'VI']">
          <router-link id="linkUser" class="a-sidebar" to="/user">
            Usuários</router-link>
        </b-nav-item>
        <b-nav-item v-has-permission.permission="['PRO', 'VI']">
          <router-link id="linkprofile" class="a-sidebar" to="/profile">
            Perfil</router-link>
        </b-nav-item>
        <b-nav-item v-has-permission.permission="['AIP', 'VI']">
          <router-link id="linkIp" class="a-sidebar" to="/ip"> IP</router-link>
        </b-nav-item>

        <div class="dropdown-divider _divider-menu"></div>
        <b-nav-item v-has-permission.permission="['RER', 'VI']">
          <router-link id="linkWebReport" class="a-sidebar" to="/webreport">
            Relatório da rede</router-link>
        </b-nav-item>
        <b-nav-item v-has-permission.permission="['RET', 'VI']">
          <router-link id="linkCtoReport" class="a-sidebar" to="/ctoreport">
            Relatório de CTO's</router-link>
        </b-nav-item>
        <b-nav-item v-has-permission.permission="['REO', 'VI']">
          <router-link id="linkCeoReport" class="a-sidebar" to="/ceoreport">
            Relatório de CEO's</router-link>
        </b-nav-item>
        <b-nav-item v-has-permission.permission="['REC', 'VI']">
          <router-link id="linkClientReport" class="a-sidebar" to="/clientreport">
            Relatório de clientes</router-link>
        </b-nav-item>
        <b-nav-item v-has-permission.permission="['RPO', 'VI']">
          <router-link id="linkPoleReport" class="a-sidebar" to="/polereport">
            Relatório de postes</router-link>
        </b-nav-item>
        <b-nav-item v-has-permission.permission="['REI', 'VI']">
          <router-link id="linkClientSignalReport" class="a-sidebar" to="/clientsignalreport">
            Relatório de sinal</router-link>
        </b-nav-item>
        <b-nav-item v-has-permission.permission="['REB', 'VI']">
          <router-link id="linkCableReport" class="a-sidebar" to="/cablereport">
            Relatório de cabos</router-link>
        </b-nav-item>
        <b-nav-item v-has-permission.permission="['REV', 'VI']">
          <router-link id="linkCondominiumReport" class="a-sidebar" to="/condominiumreport">
            Relatório de condomínios</router-link>
        </b-nav-item>
        <b-nav-item v-has-permission.permission="['FUN', 'VI']">
          <router-link id="linkFunnel" class="a-sidebar" to="/funnel">
            Funil de vendas</router-link>
        </b-nav-item>
        <div class="dropdown-divider _divider-menu"></div>
        <b-nav-item v-has-permission.permission="['ORG', 'VI']">
          <router-link id="linkWebOrganizer" class="a-sidebar" to="/webOrganizer">
            Organizador de rede</router-link>
        </b-nav-item>
        <b-nav-item @click="exit" link-classes="a-sidebar">Sair</b-nav-item>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { mapActions } from "vuex";
import { sendLogout } from '../MessageBus';

export default {
  name: "SideBar",
  data() {
    return {
      visible: true
    };
  },
  methods: {
    exit() {
      // this.logout();
      // tokenStore.set(null);
      // this.setFolderSecundary();
      // this.setFolder(null);
      // this.$router.go("/login");
      sendLogout()
    },
    ...mapActions({
      logout: "logout",
      setFolderSecundary: "setFolderSecundary",
      setFolder: "setFolder"
    })
  }
};
</script>

<style scoped>
._divider-menu {
  border-top: 1px solid #4f555a;
}

.card {
  background-color: transparent;
  border: 0px;
}

.sidebar-button {
  color: white;
  font-weight: 600;
}

.sidebar-button:hover {
  color: #0188F8;
}
</style>
