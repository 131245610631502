<template>
    <div class="created-date" v-if="date">
        Criado em: {{ formattedDate }}
    </div>
</template>

<script>
export default {
    name: "CreationDate",
    props: {
        date: {
            type: String,
            required: false,
        },
    },
    computed: {
        formattedDate() {
            if (!this.date) return "";
            const options = { year: "numeric", month: "long", day: "numeric" };
            return new Date(this.date).toLocaleDateString("pt-BR", options);
        },
    },
};
</script>

<style scoped>
.created-date {
    font-size: 0.75rem;
    color: #312f2f;
}
</style>