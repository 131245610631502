<template>
    <div class="social-icons">
      <a
        v-if="facebook"
        href="https://www.facebook.com/geocodebr/"
        target="_blank"
        title="Facebook"
        class="social-link"
      >
        <i class="bi bi-facebook social-icon"
            :style="{ color: iconColor }"
        ></i>
      </a>
      <a
        v-if="instagram"
        href="https://www.instagram.com/geocodebr/"
        target="_blank"
        title="Instagram"
        class="social-link"
      >
        <i class="bi bi-instagram social-icon"
            :style="{ color: iconColor }"
        ></i>
      </a>
      <a
        v-if="linkedin"
        href="https://www.linkedin.com/company/geocode-sistemas/"
        target="_blank"
        title="LinkedIn"
        class="social-link"
      >
        <i class="bi bi-linkedin social-icon"
            :style="{ color: iconColor }"
        ></i>
      </a>
    </div>
  </template>
  
  <script>
  export default {
    name: "SocialIcons",
    props: {
      facebook: {
        type: Boolean,
        default: true,
      },
      instagram: {
        type: Boolean,
        default: true,
      },
      linkedin: {
        type: Boolean,
        default: true,
      },
      iconColor: {
        type: String,
        default: '#fff'
      }
    },
  };
  </script>
  
  <style scoped>
  .social-icons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .social-link {
    margin: 0 10px;
  }
  
  .social-icon {
    font-size: 30px;
    transition: color 0.3s;
  }
  
  .social-icon:hover {
    color: #0188F8 !important;
  }
  </style>
  