<script>
import BaseRequest from "../../../../request/request";
import { openSubPopup } from "../index";
import { sucessMessage, getAction } from "../../../../components/MessageBus";
import ConfirmDialog from "../../../../components/extra/ConfirmDialog";

export default {
  name: "TabsBase",
  mixins: [BaseRequest, ConfirmDialog],
  props: [
    "equipmentId",
    "updateAction",
    "projectId",
    "showAttendance",
    "folderId",
    "id",
  ],
  data() {
    return {
      list: [],
      type: "",
      path: {},
    };
  },
  methods: {
    add() {
      openSubPopup(
        this.type,
        "",
        this.equipmentId,
        null,
        this.projectId,
        { showAttendance: this.showAttendance },
        this.folderId
      );
      getAction((data) => {
        if (data) {
          if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
              this.list.push(data[i]);
            }
          } else {
            this.list.push(data);
          }
          if (this.updateAction) this.updateAction();
        }
      });
    },
    edit(id) {
      //console.log("ididididid",id)
      openSubPopup(this.type, id, this.equipmentId, null, this.projectId, {
        showAttendance: this.showAttendance,
      });
      getAction((data) => {
        if (data) {
          let position = -1;
          this.list.map((entity, index) => {
            if (entity.id == data.id) {
              position = index;
            }
          });
          if (position > -1) {
            this.list.splice(position, 1, data);
          }
        }
      });
    },
    remove(id, index) {
      // if (confirm('Deseja mesmo excluir ?')) {
      // this.showConfirm("Deseja mesmo excluir ?", null, () => {
      this.$confirmDialog.open("Deseja mesmo excluir ?", () => {
        this.baseRequest("delete", this.path(this.equipmentId) + "/" + id).then(
          () => {
            sucessMessage("Excluido com sucesso");
            this.list.splice(index, 1);

            if (this.updateAction) this.updateAction();
          }
        );
      });
    },
    load() {
      if (this.list.length < 1) {
        this.baseRequest("get", this.path(this.equipmentId)).then((data) => {
          this.list = data;
        });
      }
    },
    loadSimple() {
      if (this.list.length < 1) {
        this.baseRequest("get", this.path).then((data) => {
          this.list = data;
        });
      }
    },
  },
};
</script>
