<template>
  <TemplatePopup :fullScreen="fullScreen">
    <div slot="header" class="pt-1 pl-1">
      Elementos Selecionados
    </div>
    <div slot="body">
      <b-table-simple hover small>
        <b-thead>
          <b-tr>
            <b-th class="width-50">Tipo</b-th>
            <b-th>Nome</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="item in listItens"
            :key="item.id"
            @click.prevent="select(item)"
            style="cursor: pointer"
          >
            <b-td>
              <MapElementImg :type="item.type" />
            </b-td>
            <b-td >
              {{ item.name }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </TemplatePopup>
</template>

<script>
import TemplatePopup from "../../../template/TemplatePopup";
import { openPopupMap } from "../../../view/map/popup";
import MapElementImg from "../../../components/extra/MapElementImg";
import {
  sendMapAction,
  clearMap,
  closePopupBus,
  sendAction
} from "../../../components/MessageBus";

export default {
  name: "MultiSelect",
  props: ["listItens", "fullScreen", "extra"],
  components: {
    TemplatePopup,
    MapElementImg,
  },
  methods: {
    select(item) {
      if (!this.extra) {
        this.exit();
        openPopupMap(
          {
            id: item.id,
            type: item.type,
            folderId: item.folderId,
          },
          null,
          item.projectId
        );
      } else {
        closePopupBus();
        sendAction(item);
      }
    },
    exit() {
      sendMapAction(clearMap);
      closePopupBus();
      // this.$emit("close");
    },
  },
  mounted() {},
};
</script>
