<template>
  <div class="position-relative">
    <div class="d-flex align-content-center flex-wrap bd-highlight justify-content-center" style="height: 100vh">
      <b-card class="mb-2 text-center shadow w-400 mx-2 login-card-border" bg-variant="020f40" style="background-color: #020f40" align="center">
        <b-card-text>
          <img class="pb-3 mt-2" src="../assets/img/logo_branca.png" />
          <slot name="body"></slot>
          <div class="small text-light fw-500 mt-1">${MAP-VERSION-MAP}</div>
        </b-card-text>
      </b-card>
      <div class="social-icons-container">
          <SocialIcons />
      </div>
      <slot name="movie"> </slot>
    </div>
  </div>
</template>

<script>
import SocialIcons from "../components/extra/SocialIcons";
export default {
  name: "LoginLayout",
  components: {
    SocialIcons,
  },
};
</script>

<style scoped>
.login-card-border {
  border: 1px solid #0188f8 !important;
  /* border-color: #0188F8; */
}

.bd-highlight {
  background-color: #020f40;
  color: #eceef3;
}

._input {
  font-size: 16px;
  background-color: transparent;
  padding: 10px;
  height: 40px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 3px;
  color: #dcddde;
  box-sizing: border-box;
  border: 1px solid #212327;
}

.social-icons-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.label-login {
  font-weight: 600;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
}

.w-400 {
  width: 400px;
}

@media only screen and (max-width: 770px) {
  .h-440 {
    display: none;
  }
  .m-card {
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: 0px 0px 0px black !important;
  }

  .login-card-border {
    border: 1px solid transparent !important;
  }
}

.h-440 {
  height: 440px;
}

.fw-500 {
  font-weight: 500;
}

.primary-color:hover {
  color: #0188f8;
  text-decoration: 0;
}
.primary-color {
  color: #fff;
}

.gray-color {
  color: #8e9297;
}
</style>
