<template>
  <div class="card card-comp width-card">
    <div class="row">
      <div class="col-2 align-self-center">
        <span class="badge ml-2" :class="cssStatus">{{
          status ? "Online" : "Offline"
        }}</span>
      </div>
      <div class="col-10">
        <div class="card-body-signal" :class="cssCardUseful">
          <span class="paragraph-15-weight300 pl-1">{{ text }}</span
          ><span class="fontSize15 card-value pr-1"
            >{{ value | formatNumberZero }} dBm</span
          >
          <div class="date-update">
            Data da ultima atualização:<span
              v-if="date"
              class="card-value pr-1"
              >{{ date | formatDateTime }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardSignal",
  props: ["text", "value", "status", "date"],
  computed: {
    cssCardUseful: function () {
      const value = Math.abs(this.value);
      return {
        useful_off: !this.status && value,
        useful_0_25: this.status && value < 24,
        useful_25_50: this.status && value >= 24 && value < 26,
        useful_75_100: this.status && value >= 26,
      };
    },
    cssStatus: function () {
      return {
        "badge-success": this.status,
        "badge-danger": !this.status,
      };
    },
  },
};
</script>

<style>
.card-comp {
  margin-left: -15px;
  margin-top: 14px;
  margin-bottom: 0px;
  border-radius: 0px;
  border-radius: 0.2rem;
}

.status_icon_signal {
  text-align: center;
}

.card-body-signal {
  padding: 0.3rem;
}

.icon-status {
  font-size: 1.8rem;
  font-weight: 700;
}

.icon-signal-online {
  color: #27ae60;
}

.icon-signal-offline {
  color: #e74c3c;
}

.date-update {
  font-size: 11px;
  margin-left: 0.25rem;
}

.date-value {
  font-size: 10px;
  color: #4c4c4c;
  font-weight: 600;
}
</style>
