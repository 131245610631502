<template>
  <b-card>
    <b-card-text>
      <span class="paragraph-20-weight300">{{ text }}</span
      ><span class="fontSize20 card-value">{{ value | formatNumberZero }}</span>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: "CardValue",
  props: ["text", "value"],
};
</script>


