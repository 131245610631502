import  store  from '../../store'

export default function () {
  return new Promise((resolve, reject) => {
    const API_KEY = store.getters.getGoogleKey;
    window['GoogleMapsInit'] = resolve;
    window.google = null

    let script

    if (script)
      document.querySelector('body').removeChild(script)

    script = document.createElement('script');
    script.type = 'text/javascript';
    script.onerror = reject;

    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=GoogleMapsInit&loading=async`

    document.querySelector('body').appendChild(script);

    // console.log('CREATED SCRIPT', script, window.google, google)
  })
}
