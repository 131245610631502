<template>
  <div class="">
    <span class="badge fw-15" v-if="text">
      <span>{{ text }}</span>
    </span>
    <span class="badge fw-15 floatRight" :class="cssUsage"
      >{{ value | formatNumber }} %</span
    >
  </div>
</template>

<script>
export default {
  name: "UsageComponent",
  props: ["text", "value"],
  computed: {
    cssUsage: function () {
      return {
        active: true,
        useful_0_25: this.value > -1 && this.value < 26,
        useful_25_50: this.value > 25 && this.value < 51,
        useful_50_75: this.value > 50 && this.value < 76,
        useful_75_100: this.value > 75,
      };
    },
  },
};
</script>
