<template>
 <div>
    <div v-if="localMessage" class="modal-counter">{{ localMessage }}</div>
 </div>
</template>

<script>
import { getCounter } from '../MessageBus';

export default {
  name: 'ModalCounter',
  data() {
    return {
      localMessage:""
    };
  },
  mounted() {
    getCounter(data => {
     this.localMessage = data;
     })
  },
}
</script>

<style>
.modal-counter {
  position: absolute;
  float: left;
  left: 50%;
  top: 57%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc !important;
  border-radius: 16px;
  padding: 15px;
  background-color: #fff;
  z-index: 10000010 !important;
}
</style>
