<template>
  <div>
    <b-table
      empty-text="Não existe registros para serem apresentados na lista."
      :items="list"
      :fields="fields"
      responsive
      show-empty
      small
      striped
    >
      <template #cell(type)="row">
        <CableType :type="row.item.type" />
      </template>
      <template #cell(actions)="row">
        <FolderStatus :status="row.item.statusFolder" :label="false" />
      </template>
      <template #cell(location)="row">
        <b-button 
          size="sm"
          variant="warning" 
          @click.prevent="showCableMap(row.item)"
        >  
            <i class="fa fa-map-marker"></i>  
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import BaseRequest from "../../../../request/request";
import CableType from "../../../../components/extra/CableType";
import { cablesInfoPath } from "../../../../router/ApiPaths";
import FolderStatus from "../../../../components/extra/FolderStatus";
import { sendMapAction, addLineMap } from "../../../../components/MessageBus";
import { minimizeAll } from "../../../../components/extra/HideModals";

export default {
  name: "CablesTab",
  mixins: [BaseRequest],
  props: ["equipmentId", "projectId"],
  components: {
    CableType,
    FolderStatus,
  },
  data() {
    return {
      list: [],
      fields: [],
    };
  },
  mounted() {
    this.load();
    this.fields = [
      {
        key: "name",
        label: "Nome",
      },
      {
        key: "type",
        label: "Tipo",
      },
      {
        key: "nameCableModel",
        label: "Modelo",
      },
      {
        key: "nameCableModel",
        label: "Modelo",
      },
      {
        key: "usage",
        label: "Taxa ocupação",
      },
      {
        key: "nameFolder",
        label: "Pasta",
      },
      {
        key: "actions",
        label: "Status",
      },
      {
        key: "location",
        label: "Localização",
        class: "text-right width-50",
      },
    ];
  },
  methods: {
    load() {
      if (this.list.length < 1) {
        let path = cablesInfoPath(this.equipmentId);

        // if (this.projectId) {
        //   path = cablesInfoProjectPath(this.projectId, this.equipmentId);
        // }

        this.baseRequest("get", path).then((data) => {
          this.list = data;
        });
      }
    },
    showCableMap(entity, dontFit) {
      sendMapAction(addLineMap, {
        coordinates: entity.geometry.coordinates,
        clear: false,
        dontFit
      });
      minimizeAll();
    },
  },
};
</script>
