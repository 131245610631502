<template>
  <TemplateLogin>
    <div slot="body">
      <b-form-group class="text-left">
        <label class="label-login">E-mail</label>
        <b-form-input
          id="inputLogin"
          v-model="entity.login"
          :class="{ 'is-invalid': missingField }"
          placeholder="Digite o e-mail para recuperaçao de senha"
        ></b-form-input>
      </b-form-group>

      <div class="text-left label-login mt-2 mb-3 fw-500">
        <router-link
          id="linkBack"
          title="Voltar"
          class="primary-color"
          :to="{ name: 'Login' }"
          >Voltar para tela de login</router-link
        >
      </div>
      <b-button
        block
        variant="danger"
        id="btnForgot"
        class="login-button mb-3"
        :disabled="buttonDisabled"
        @click.prevent="send()"
        >Enviar</b-button
      >
    </div>
  </TemplateLogin>
</template>

<script>
import BaseRequest from "../../request/request";
import { forgotPasswordPath } from "../../router/ApiPaths";
import { sucessMessage } from "../../components/MessageBus";
import TemplateLogin from "../../template/TemplateLogin";

export default {
  name: "forgotPassword",
  data() {
    return {
      entity: {},
    };
  },
  mixins: [BaseRequest],
  components: {
    TemplateLogin,
  },
  methods: {
    send() {
      this.baseRequest("post", forgotPasswordPath, this.entity).then(() => {
        sucessMessage(
          "A senha será alterada e enviada para o e-mail registrado no sistema, aguarde alguns instantes até receber o mesmo"
        );
        this.$router.push({ path: "/login" });
      });
    },
  },
  computed: {
    missingField: function () {
      return !this.entity.login;
    },
    buttonDisabled: function () {
      return this.missingField;
    },
  },
};
</script>