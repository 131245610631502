<template>
  <b-modal id="confirmDialog" no-close-on-backdrop hide-header-close no-close-on-esc title="Confirmar" centered v-model="modalShow">
    <template #modal-header>
      <h6 class="m-0"><strong>Confirmar</strong></h6>
      <b-button-close v-if="showCloseButton" class="close" @click="closeModal"></b-button-close>
    </template>

    <div>{{ text }}</div>

    <template #modal-footer>
      <b-button size="sm" v-if="customButtons" v-for="button in customButtons" :key="button.text" :variant="button.variant" @click="resolveCustom(button)">
        {{ button.text }}
      </b-button>

      <b-button size="sm" variant="success" v-if="showYesNo" @click="resolve(true)"> Sim </b-button>
      <b-button size="sm" variant="danger" v-if="showYesNo" @click="resolve(false)"> Não </b-button>
      <b-button size="sm" variant="success" v-show="isAlert" @click="resolve(true)"> Ok </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ConfirmDialog",
  data() {
    return {
      modalShow: false,
      resolvePromisse: null,
      text: "",
      cb: null,
      cbCancel: null,
      showYesNo: false,
      isAlert: false,
      customButtons: null,
      showCloseButton: false
    };
  },
  methods: {
    open(text, cb, cbCancel) {
      this.text = text;
      this.showYesNo = true;
      this.isAlert = false,
      this.$bvModal.show("confirmDialog");
      this.cb = cb;
      this.cbCancel = cbCancel;
    },
    openWithCustomButtons(text, buttons, showCloseButton = false) {
      this.text = text;
      this.showYesNo = false;
      this.isAlert = false;
      this.customButtons = buttons;
      this.showCloseButton = showCloseButton;
      this.$bvModal.show("confirmDialog");
    },
    resolve(answer) {
      if (this.cb && answer) this.cb();
      if (this.cbCancel && !answer) this.cbCancel();
      this.cb = null;
      this.cbCancel = null;
      this.$bvModal.hide("confirmDialog");
    },
    resolveCustom(button) {
      if (button.cb) button.cb();
      this.$bvModal.hide("confirmDialog");
    },
    alert(text){
      this.text = text;
      this.showYesNo = false;
      this.isAlert = true
      this.$bvModal.show("confirmDialog");
    },
    okClick() {
      this.$bvModal.hide("confirmDialog");
    },
    closeModal() {
      this.$bvModal.hide("confirmDialog");
    },
  },
};
</script>
<style scoped>
.close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  font-size: 1.5rem;
  color: #020f40; 
}
.close:hover {
  color: #d9534f;
}
</style>