<template>
  <TemplatePopup :showMessage="saveDisabled" :fullScreen="fullScreen" :geometry="entity.geometry">
    <div slot="header" class="header-container">
      <div class="header-title">
        <strong>Condomínio</strong>
        <CreationDate :date="entity.created" />
      </div>
    </div>    <div slot="body">
      <tabs>
        <tab title="Dados" :selected="true">
          <b-form-group label="Tipo de Condomínio:">
            <b-form-select
              id="selectCondominiumType"
              v-model="entity.condominiumType"
              :options="options"
              :class="{ 'is-invalid': missingCondominiumType }"
              size="sm"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Nome do prédio:">
            <b-form-input id="inputName" v-model="entity.name" :class="{ 'is-invalid': missingName }" maxlength="50"
              size="sm"></b-form-input>
          </b-form-group>
          <b-form-group label="Identificação extra:">
            <b-form-input id="inputIdentification" v-model="entity.identification" maxlength="50"
              size="sm"></b-form-input>
          </b-form-group>
          <!-- <b-form-group label="Quantidade de andares:">
            <b-form-input id="inputFloor" v-model.number="entity.floor" :class="{ 'is-invalid': missingFloor }"
              v-on:keypress="isNumber" maxlength="2" size="sm"></b-form-input>
          </b-form-group> -->

          <b-form-group label="Endereço:">
            <b-form-input id="addressList" v-model="entity.address" maxlength="255"
              :class="{ 'is-invalid': missingAddress }" size="sm"></b-form-input>
          </b-form-group>

          <b-form-group label="Endereços sugeridos:" v-if="isNew">
            <b-form-select id="addressList" v-model="entity.address" size="sm">
              <b-form-select-option v-for="address in addressList" :value="address.formatted_address"
                :key="address.formatted_address">
                {{ address.formatted_address }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group label="Pasta:">
            <b-form-select id="selectFolder" v-model="entity.folderId" size="sm">
              <b-form-select-option v-for="entityList in folderList" :value="entityList.id" :key="entityList.id">
                {{ entityList.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </tab>
        <tab title="Cabos" v-if="!isNew" :permission="['CAB', 'VI']">
          <Cables :equipmentId="entity.id" />
        </tab>
        <tab title="Contato" v-if="!isNew" :permission="['CTT', 'VI']">
          <Contact :equipmentId="entity.id" />
        </tab>
        <tab title="Blocos" v-if="!isNew" :permission="['BLO', 'VI']">
          <Block :equipmentId="entity.id" />
        </tab>
      </tabs>
    </div>
    <div slot="button">
      <div class="text-center">
        <b-button-group class="my-2">
          <b-button id="btnBuilding" :disabled="isNew" @click.prevent="building()"
            v-has-permission.permission="['CMV', 'VI']" variant="muted">
            <i class="fa fa-building" aria-hidden="true"></i>
            <p class="button-text">Diagrama</p>
          </b-button>

          <b-button id="btnComments" :disabled="isNew" @click.prevent="comment()"
            v-has-permission.permission="['CMV', 'VI']" variant="muted">
            <i class="fa fa-comments fa-lg" aria-hidden="true"></i>
            <p class="button-text">Comentários</p>
          </b-button>

          <b-button
            id="btnImage"
            @click.prevent="image()"
            v-has-permission.permission="['IMG', 'VI']"
            variant="muted"
            :disabled="isNew"
          >
            <i class="fa fa-picture-o fa-lg" aria-hidden="true"></i>
            <p class="button-text">Imagem</p>
          </b-button>

          <b-button id="btnDelete" :disabled="isNew" @click.prevent="remove()" v-has-permission.permission="['CMV', 'EX']"
            variant="muted">
            <i class="fa fa-times-circle fa-lg" aria-hidden="true"></i>
            <p class="button-text">Excluir</p>
          </b-button>

          <b-button id="btnSave" :disabled="saveDisabled" @click.prevent="moveElements()"
            v-has-permission.permission="['CMV', 'AL']" variant="muted">
            <i class="fa fa-floppy-o fa-lg"></i>
            <p class="button-text">Salvar</p>
          </b-button>
        </b-button-group>
      </div>
    </div>
  </TemplatePopup>
</template>

<script>
import TemplatePopup from "../../../template/TemplatePopup";
import PopupBase from "./PopupBase";
import {
  verticalCondominiumPath,
  folderSimplePath,
} from "../../../router/ApiPaths";
import Tabs from "./Tabs/Tabs";
import Tab from "./Tabs/Tab";
import OnlyNumber from "../../../components/extra/OnlyNumber";
import Cables from "./Tabs/Cables";
import ConfirmDialog from "../../../components/extra/ConfirmDialog.vue";
// import { updateAllMap } from "../../../components/MessageBus";
import Contact from "./Tabs/Contact";
import Block from "./Tabs/Block";
import CreationDate from "./CreationDate"

export default {
  name: "VerticalCondominium",
  extends: PopupBase,
  mixins: [OnlyNumber, ConfirmDialog],
  data() {
    return {
      folderList: [],
      internalEquipment: false,
      hasFolderChanged: false,
      originalId: null,
      options: [
        { value: "R", text: "Residencial" },
        { value: "C", text: "Comercial" },
        { value: "E", text: "Empresarial" },
        { value: "I", text: "Industrial" },
      ],
    };
  },
  watch: {
  "entity.folderId": function (newValue) {
    this.hasFolderChanged = this.originalId !== null && newValue !== this.folderId;
    this.originalId = this.hasFolderChanged ? newValue : this.folderId;
  }
},
  components: {
    TemplatePopup,
    Tabs,
    Tab,
    Cables,
    Contact,
    Block,
    CreationDate
  },
  beforeMount() {
    this.entity = {
      id: null
    }
   },
  mounted() {
    let path = verticalCondominiumPath(this.folderId);

    this.getAllDirect(folderSimplePath).then((data) => {
      this.folderList = data;
    });
    this.init(path, true, this.isNew);
  },
  computed: {
    missingName: function () {
      return !this.entity.name;
    },
    missingAddress: function () {
      return !this.entity.address;
    },
    missingCondominiumType: function() {
      return !this.entity.condominiumType;
    },
    saveDisabled: function () {
      return this.missingName || this.missingAddress || this.missingCondominiumType || !this.isDirty;
    },
  },
  methods: {
    moveElements() {
      if (this.hasFolderChanged) {
        this.$confirmDialog.open(
          "Há equipamentos dentro do diagrama de conexões do condomínio vertical. Deseja alterar todos os elementos internos do diagrama?",
          () => {
            this.entity.internalEquipment = true;
            this.save();
          },
          () => {
            this.save();
          }
        );
      } else {
        this.save();
      }

    },
  },
};
</script>
