<template>
  <div id="connectedClients">
    <div class="d-flex justify-content-end mb-2">
      <b-button
        id="btnAllCables"
        size="sm"
        variant="success" 
        class="mr-2"
        title="Exibir todos" 
        :disabled="!list.length"
        @click.prevent="showAllCables()">
        Ver todos os cabos
      </b-button>
      <b-button
        id="btnExport"
        size="sm"
        variant="success" 
        title="Exibir todos" 
        :disabled="!list.length"
        @click.prevent="print()">
        Exportar
      </b-button>
    </div>
    
    <b-table 
      empty-text="Não existe registros para serem apresentados na lista."
      :items="list"
      :fields="fields" 
      responsive
      show-empty 
      small 
      striped>


      <template #cell(name)="row">
        {{ row.item.clientName }}
      </template>

      <template #cell(cable)="row">
        {{ row.item.cableName }}
      </template>
      <template #cell(cableSize)="row">
        {{ row.item.cableSize }} m
      </template>

      <template #cell(location)="row">
        <b-button 
          size="sm"
          variant="warning" 
          @click.prevent="showCableMap(row.item)"
        >  
            <i class="fa fa-map-marker"></i>  
        </b-button>
      </template>
      
    </b-table>
  </div>
</template>
  
<script>
import TabsBase from "./TabsBase";
import { findClientPath } from "../../../../router/ApiPaths";
import BaseRequest from "../../../../request/request";
import { sendMapAction, addLineMap } from "../../../../components/MessageBus";
import PrintDiagram from "../components/PrintDiagram";
import { minimizeAll } from "../../../../components/extra/HideModals";

export default {
  name: "ConnectedClients",
  extends: TabsBase,
  mixins: [BaseRequest],
  props: ["equipmentId", "folderId"],
  data() {
    return {
      fields: [],
      list: []
    };
  },
  mounted() {
    this.type = 2;
    this.load();
    this.fields = [
      {
        key: "name",
        label: "Nome",
      },
      {
        key: "cable",
        label: "Cabo",
      },
      {
        key: "cableSize",
        label: "Tamanho total",
      },
      // {
      //   key: "clientDoor",
      //   label: "Porta",
      // },
      {
        key: "location",
        label: "Localização",
        class: "text-right width-150",
      },
    ];
  },
  methods: {
    load() {
      let path = findClientPath(this.equipmentId);

      this.baseRequest("get", path).then((data) => {
        this.list = data;
      });
    },
    showCableMap(entity, dontFit) {
      sendMapAction(addLineMap, {
        coordinates: entity.cableGeometry.coordinates,
        clear: false,
        dontFit
      });
      minimizeAll();
    },
    showAllCables() {
      this.list.forEach((item, idx) => {
        this.showCableMap(item, idx + 1 === this.list.length );
      });
    },
    print(){
      PrintDiagram("connectedClients", "CTO - Clientes conectados", ["btnAllCables", "btnExport"])
    }
  }
};
</script>
  