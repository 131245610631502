<template>
  <div class="tags has-addons">
    {{typeText}}
  </div>
</template>

<script>

export default {
  name: 'CableType',
  props: ['type'],
  computed: {
    typeText() {
      if (this.type === 'D') {
        return 'Cabo equipamento';
      } else {
        return 'Cabo cliente';
      }
    },
  }
};

</script>
