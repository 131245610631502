<script>
import BaseRequest from "../../../request/request";
import { mapGetters } from "vuex";
import {
  sucessMessage,
  infoMessage,
  sendMapAction,
  clearMap,
  updateMap,
  addMap,
  removeMap,
  getAction,
  sendAction,
  refreshCables,
  closePopupBus,
  sendBuildingAction,
  drawElementAction,
} from "../../../components/MessageBus";
import GoogleService from "../../../components/extra/GoogleService";
import {
  cablePointPath,
  equipmentLimitExistsPath,
} from "../../../router/ApiPaths";
import { openSubPopup } from "./index";
import ConfirmDialog from "../../../components/extra/ConfirmDialog";
import { minimizeAll } from "../../../components/extra/HideModals";

export default {
  name: "PopupBase",
  props: [
    "id",
    "geometry",
    "equipmentId",
    "fullScreen",
    "folderIdMap",
    "extra",
    "featureType",
  ],
  mixins: [BaseRequest, GoogleService, ConfirmDialog],
  data() {
    return {
      entity: {
        address: "",
        id: null,
      },
      path: "",
      addressList: [],
      cableList: [],
      modelList: [],
      mapUpdate: false,
      isDirty: false,
      initialEntity: null,
    };
  },
  methods: {
    init(path, findAddress, findCable) {
      this.path = path;
      if (this.id) {
        this.path += "/" + this.id;
        this.getOne();
      } else {
        this.entity.geometry = this.geometry;
        if (findAddress) {
          this.getAddressPoint(this.entity.geometry.coordinates).then(
            (addressList) => {
              this.addressList = addressList;
              if (addressList.length > 0) {
                this.entity.address = addressList[0].formatted_address;
              }
            }
          );
        }
        if (findCable) {
          let cablePath = cablePointPath(this.folderId);
          this.geometry.openedFolders = this.getAllFoldersId;
          this.putDirect(cablePath, this.geometry).then((data) => {
            this.cableList = data;
          });
        }
      }
    },
    getAllDirect(path) {
      return this.baseRequest("get", path);
    },
    putDirect(path, entity) {
      return this.baseRequest("put", path, entity);
    },
    getOne(callback) {
      this.baseRequest("get", this.path).then((data) => {
        this.entity = data;
        this.initialEntity = JSON.stringify(this.entity);
        if (callback) callback(data);
      });
    },
    put() {
      if (!this.id) this.path += "/" + this.entity.id
      this.baseRequest("put", this.path, this.entity, false).then((data) => {
        data.oldFolderId = this.folderId;
        if (this.entity.split) {
          sendMapAction(refreshCables);
        }
        this.sendMapActionMap(updateMap, data, !this.entity.split);
      });
    },
    post() {
        this.baseRequest("post", this.path, this.entity, false).then((data) => {
        this.entity = Object.assign(this.entity, data.properties);

        if (this.entity.cable || this.entity.split) {
          sendMapAction(refreshCables);
        }
        this.sendMapActionMap(addMap, data, !this.entity.split);
      });
    },
    sendMapActionMap(type, data, sendMap) {
      sucessMessage("Salvo com sucesso");
      sendAction(data);
      if (sendMap && this.entity.category !== "I") {
        sendMapAction(type, {
          featureType: data.properties ? data.properties.featureType : null,
          feature: data,
          id: this.id || this.entity.id,
        });
      }
      sendBuildingAction(type, {
        featureType: data.properties ? data.properties.featureType : null,
        feature: data,
        id: this.id || this.entity.id,
      });

      this.mapUpdate = true;
      if (type === 'U') this.exit()
      this.endLoading();
    },
    save() {
      if (this.isDirty) {
        this.entity.openedFolders = this.getAllFoldersId;
        if (this.entity.id) {
          this.put();
        } else {
          this.post();
        }
      }
    },
    remove() {
      const deleteEqp = () => {
        if (!this.id) this.path += "/" + this.entity.id;
        this.baseRequest("delete", this.path).then(() => {
          sendMapAction(removeMap, {
            featureType: this.featureType,
            id: this.id || this.entity.id,
            folderId: this.folderId,
          });
          sendBuildingAction(removeMap, {
            featureType: this.featureType,
            id: this.id || this.entity.id,
            folderId: this.folderId,
          });
          this.exit();
        });
      };
      this.$confirmDialog.open("Deseja mesmo excluir?", deleteEqp);
    },
    setAddress(address) {
      this.entity.address = address;
    },
    exit() {
      closePopupBus();
    },
    conexao() {
      openSubPopup(3, null, this.id || this.entity.id, this.entity);

      getAction((result) => {
        if (result) {
          this.getOne();
        }
      });
    },
    connectionReport() {
      openSubPopup(7, null, this.id || this.entity.id);
    },
    image(extra) {
      openSubPopup(8, null, this.id || this.entity.id, null, null, extra);
    },
    comment() {
      openSubPopup(11, null, this.id || this.entity.id);
    },
    OTDR() {
      openSubPopup(28, null, this.id || this.entity.id);
    },
    building() {
      openSubPopup(29, null, null, this.entity, null, null, this.folderId);
      getAction((result) => {
        if (result) {
          this.getOne();
        }
      });
    },
    drawnLimit() {
      this.get(equipmentLimitExistsPath(this.folderId, this.entity.id)).then(
        (data) => {
          if (data && !data.exists) {
            minimizeAll();
            sendMapAction(drawElementAction, {
              type: "EL",
              typeTool: "P",
              id: this.entity.id,
            });
          } else {
            infoMessage("Já existe área de atuação cadastrada");
          }
        }
      );
    },
    checkIfDirty() {
      const currentEntityString = JSON.stringify(this.entity);
      this.isDirty = currentEntityString !== this.initialEntity;
    },
    ...mapGetters({
      getFolder: "getFolder",
    }),
  },
  computed: {
    isNew() {
      return !this.entity.id && !this.id;
    },
    ...mapGetters({
      isLoading: "isLoading",
      getAllFoldersId: "getAllFoldersId"
    }),
    folderId() {
      return this.folderIdMap
        ? this.folderIdMap
        : this.getFolder()
          ? this.getFolder().id
          : null;
    },
    entityString() {
      return JSON.stringify(this.entity);
    }
  },
  mounted() {
    this.entity.folderId = this.folderId;
    this.initialEntity = this.entityString
  },
  beforeDestroy() {
    sendMapAction(clearMap);
  },
  watch: {
    entityString() {
      this.checkIfDirty();
    },
  },
};
</script>