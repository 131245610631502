<template>
  <TemplatePopup :showMessage="saveDisabled" :fullScreen="fullScreen">
    <div slot="header">Poligono</div>
    <div slot="body">
      <b-form-group label="Nome:">
        <b-form-input
          id="inputName"
          v-model="entity.name"
          :class="{ 'is-invalid': missingName }"
          maxlength="20"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Pasta:">
        <b-form-select id="selectFolder" v-model="entity.folderId" size="sm">
          <b-form-select-option
            v-for="entityList in folderList"
            :value="entityList.id"
            :key="entityList.id"
          >
            {{ entityList.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <div class="form-group">
        <label>Cor</label>
        <compact-picker
          :value="baseColor"
          @input="updateValue"
          id="colorSelect"
        />
      </div>
    </div>

    <div slot="button">
      <div class="text-center">
        <b-button-group class="my-2">
          <b-button
            id="btnDelete"
            @click.prevent="remove()"
            v-has-permission.permission="['POL', 'EX']"
            variant="muted"
            :disabled="isNew"
          >
            <i class="fa fa-times-circle fa-lg" aria-hidden="true"></i>
            <p class="button-text">Excluir</p>
          </b-button>

          <b-button
            id="btnSave"
            @click.prevent="save()"
            v-has-permission.permission="['POL', 'AL']"
            variant="muted"
            :disabled="saveDisabled"
          >
            <i class="fa fa-floppy-o fa-lg"></i>
            <p class="button-text">Salvar</p>
          </b-button>
        </b-button-group>
      </div>
    </div>
  </TemplatePopup>
</template>

<script>
import TemplatePopup from "../../../template/TemplatePopup";
import { polygonPath, folderSimplePath } from "../../../router/ApiPaths";
import PopupBase from "./PopupBase";
import { Compact } from "vue-color";

export default {
  name: "PolygonMap",
  extends: PopupBase,
  components: {
    TemplatePopup,
    "compact-picker": Compact,
  },
  data() {
    return {
      colorDefult: {
        hsl: {
          h: 150,
          s: 0.5,
          l: 0.2,
          a: 1,
        },
      },
      folderList: [],
    };
  },
  mounted: function () {
    let path = polygonPath(this.folderId);
    this.init(path, false);
    this.getAllDirect(folderSimplePath).then((data) => {
      this.folderList = data;
    });
  },
  computed: {
    missingName: function () {
      return !this.entity.name;
    },
    saveDisabled: function () {
      return this.missingName  || !this.isDirty;
    },
    baseColor: function () {
      return {
        hex: this.entity.color,
      };
    },
  },
  methods: {
    updateValue(value) {
      this.entity.color = value.hex;
    },
  },
};
</script>
