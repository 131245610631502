<template>
  <TemplateLogin>
    <div slot="body">
      <b-form-group class="text-left">
        <label class="label-login">E-mail</label>
        <b-form-input
          id="inputName"
          v-model="entity.login"
          :class="{ 'is-invalid': missingLogin }"          
        ></b-form-input>
      </b-form-group>
      <b-form-group class="text-left mb-0">
        <label class="label-login">Senha</label>
        <b-form-input
          id="inputPassword"
          type="password"
          v-model="entity.password"
          v-on:keyup.enter="login()"
          :class="{ 'is-invalid': missingPassword }"          
        ></b-form-input>
      </b-form-group>
      <div class="text-left label-login mt-2 mb-3 fw-500">
        <router-link
          id="linkForgot"
          title="Esqueci a senha"
          class="primary-color"
          :to="{ name: 'forgotpassword' }"
          >Esqueci a senha</router-link
        >
      </div>

      <div id="cf-turnstile" class="text-center mt-3 mb-3" data-size="flexible"></div>

      <b-button
        block
        style="background-color: #0188F8; border-color: #0188F8;"
        id="btnLogin"
        class="login-button mb-3"
        :disabled="buttonDisabled"
        @click.prevent="login()"
        >Entrar</b-button
      >
      <a
      href="https://wa.me/+553140424391?text=Olá preciso de ajuda para acessar meu mapa"
      class="w-100"
      target="_blank"
      >
      <b-button block variant="success" class="mb-3">
          <img class="img-icon" src="../../assets/img/whatsapp.svg" alt />
          <span class="whatsapp">Fale conosco</span>
      </b-button> </a>
      
    </div>
  </TemplateLogin>
</template>

<script>
import { mapActions } from "vuex";
import md5 from "js-md5";
import TemplateLogin from "../../template/TemplateLogin";
import request, { setTokenHeader } from "../../request/request";
import { tokenStore } from "../../components/extra/LocalStorage";
import { loginPath } from "../../router/ApiPaths";
import socket from "../../socket"

export default {
  name: "LoginScreen",
  props: ["extra"],
  data() {
    return {
      entity: {},
      turnstileResponse: null,
    };
  },
  extends: request,
  components: {
    TemplateLogin,
  },
  mounted() {
    this.loadTurnstile();
  },
  methods: {
    loadTurnstile() {
      const script = document.createElement('script');
      script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        window.turnstileCallback = (token) => {
          this.turnstileResponse = token;
        };
        turnstile.render('#cf-turnstile', {
          sitekey: '0x4AAAAAAAiVMV-ncw0CzSDk',
          callback: window.turnstileCallback,
        });
      };
      document.head.appendChild(script);
    },
    login() {
      if (!this.turnstileResponse) {
        alert("Por favor, complete o CAPTCHA.");
        return;
      }

      this.baseRequest("post", loginPath, {
        login: this.entity.login,
        password: md5(this.entity.password),
        "cf-turnstile-response": this.turnstileResponse
      }).then((data) => {
        setTokenHeader(data.token);
        this.setLogin({ user: data, token: data.token });
        tokenStore.set(data.token);
        socket(data.private, data.global);
        this.$router.push({ path: "/" });
        // if (data.hasMessage) {
        // fixedMessageUnreadedMessages();
        // }
      });
    },
    ...mapActions({
      setLogin: "setLogin",
    }),
  },
  computed: {
    missingLogin: function () {
      return !this.entity.login;
    },
    missingPassword: function () {
      return !this.entity.password;
    },
    buttonDisabled: function () {
      return this.missingLogin || this.missingPassword;
    },
  },
};
</script>

<style lang="sass" scoped>
.whatsapp
  color: #ffffff
  margin-left: 5px

.img-icon
  width: 20px

.login-button:disabled 
  color: #a9a9a9

@media only screen and (max-width: 770px)
  .mobile-card
    display: none
</style>
