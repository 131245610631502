<template>
  <div>
    <ul class="nav nav-tabs">
      <li class="nav-item" v-for="tab in tabs" :key="tab.title">
        <a
          class="nav-link"
          v-if="verifyPermission(tab)"
          :class="{ active: tab.isActive }"
          :href="tab.href"
          @click="selectTab(tab)"
          >{{ tab.title }}</a
        >
      </li>
    </ul>
    <div class="tab-contents mt-2">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { hasPermission } from "../../../../components/extra/hasPermission";

export default {
  name: "tabsBase",
  data() {
    return {
      tabs: [],
    };
  },
  created() {
    this.tabs = this.$children;
  },
  methods: {
    selectTab(stab) {
      this.tabs.forEach((tab) => {
        tab.isActive = tab.title == stab.title;
        // tab.isMounted = true
      });
    },
    verifyPermission(stab) {
      if (stab.permission) {
        return hasPermission(stab.permission);
      } else {
        return true;
      }
    },
  },
};
</script>
<style>
/* .tab-content {
  padding-top: 10px;
}

.nav-tabs .nav-link.active {
    color: #fd8200;
    font-weight: 500;
    background-color: #fff;
    border-color: #fff #fff  #fd8200 #fff;
    border-bottom-width: 2px;
}

.nav-tabs {
    border-bottom: 0px;
    padding-bottom: 15px;
} */
</style>
