<template>
  <b-card :class="cssCardUseful">
    <b-card-text>
      <span class="paragraph-20-weight300">{{ text }}</span
      ><span class="fontSize20 card-value"
        >{{ value | formatNumberZero }} %</span
      >
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: "CardUsage",
  props: ["text", "value"],
  computed: {
    cssCardUseful: function () {
      return {
        useful_0_25: this.value > -1 && this.value < 26,
        useful_25_50: this.value > 25 && this.value < 51,
        useful_50_75: this.value > 50 && this.value < 76,
        useful_75_100: this.value > 75,
      };
    },
  },
};
</script>
