import io from "socket.io-client";
import store from "../store";
import { sendLogout, sendMapAction, infoMessage } from "@/components/MessageBus";

const identifyListener = (type, text) => {
  if (type == "UCP") {
    // console.log("senha");
    sendLogout();
  } else if (type == "UCR") {
    // console.log("perfil");
    sendLogout();
  }
};

export default (privateSocket, globalSocket) => {
  localStorage.debug = "*";
  const socket = io.connect({
    reconnection: true, // Habilita a reconexão automática
    reconnectionDelay: 1000, // Tempo inicial de delay para tentativas de reconexão
    reconnectionDelayMax: 5000, // Tempo máximo de delay para tentativas de reconexão
    reconnectionAttempts: Infinity, // Número ilimitado de tentativas de reconexão
    query: { id: privateSocket, global: globalSocket },
  });

  // window.vue.$on("LOSTFOCUS", () => {
  //   console.log("LOSTFOCUS");
  // });

  // window.vue.$on("INFOCUS", () => {
  //   console.log("INFOCUS");
  // });

  socket.on("connect", () => {
    store.dispatch("setOn");
  });

  socket.on("disconnect", () => {
    store.dispatch("setOff");
  });

  socket.on("connect_error", (error) => {
    store.dispatch("setOff");
  });

  // console.log(privateSocket, globalSocket, socket);

  socket.off(privateSocket);
  socket.on(privateSocket, (msg) => {
    if (msg && msg.type && msg.text) {
      identifyListener(msg.type, msg.text);
    }
  });
  socket.off(globalSocket);
  socket.on(globalSocket, (msg) => {
    const userId = store.getters["getUser"].id;
    const openedFolders = store.getters["getAllFoldersId"];
    const { oldFolder, newFolder } = msg.data.properties;
    const { action, data } = msg;
  
    if (!store) return;
  
    const isDifferentUser = String(userId) !== String(data.properties.userId);

    const hasRelevantFolder =
        (data.properties.folderId && openedFolders.includes(parseInt(data.properties.folderId))) ||
        (data.properties.equipment?.folderId && openedFolders.includes(parseInt(data.properties.equipment.folderId))) ||
        (oldFolder?.id && openedFolders.includes(parseInt(oldFolder.id))) ||
        (newFolder?.id && openedFolders.includes(parseInt(newFolder.id)));

    if (isDifferentUser && hasRelevantFolder) {
      if (action !== "f") {
        const actionConverted = { d: "R", c: "A", u: "U" };
        sendMapAction(actionConverted[action], {
          featureType: data.properties?.featureType || null,
          feature: data || null,
          id: data.properties.id,
          folderId: data.properties?.folderId || null,
        });
      } else {
        const message = `Movimentação realizada: itens da pasta <strong>${oldFolder.name}</strong> 
          foram movidos para <strong>${newFolder.name}</strong>. 
          <span class="alert-link" style="cursor: pointer; color: #007bff; text-decoration: underline; font-weight: bold;" id="reopen-folder">
          Recarregue</span> ambas as pastas para conferir as atualizações.`;
  
        infoMessage(message, true, () => {
          sendMapAction("UA");
        });
      }
    }
  
    store.dispatch("setNotification", true);
    console.log("SET NOTIFICATION", msg);
  });
};
