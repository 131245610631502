<template>
  <TemplatePopup
    :showMessage="false"
    :fullScreen="fullScreen"
    :geometry="entity.geometry"
  >
    <div slot="header">Poste</div>
    <div slot="body">
      <b-form-group label="Nome:">
        <b-form-input
          id="inputName"
          v-model="entity.name"
          maxlength="50"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Identificação:">
        <b-form-input
          id="inputIdentification"
          v-model="entity.identification"
          maxlength="24"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Kit de ferragem:">
        <b-form-select id="inputKitList" v-model="entity.kitId" size="sm">
          <b-form-select-option
            v-for="entityList in kitList"
            :value="entityList.id"
            :key="entityList.id"
          >
            {{ entityList.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group label="Pasta:">
        <b-form-select id="selectFolder" v-model="entity.folderId" size="sm">
          <b-form-select-option
            v-for="entityList in folderList"
            :value="entityList.id"
            :key="entityList.id"
          >
            {{ entityList.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox
          id="inputAuthorized"
          checked
          v-model="entity.authorized"
        >
          Poste autorizado
        </b-form-checkbox>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox
          id="inputTransformer"
          checked
          v-model="entity.transformer"
        >
          Poste com transformador
        </b-form-checkbox>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox id="inputWood" checked v-model="entity.wood">
          Poste de madeira
        </b-form-checkbox>
      </b-form-group>
    </div>

    <div slot="button">
      <div class="text-center">
        <b-button-group class="my-2">
          <b-button
            id="btnDelete"
            @click.prevent="remove()"
            v-has-permission.permission="['POS', 'EX']"
            variant="muted"
            :disabled="isNew"
          >
            <i class="fa fa-times-circle fa-lg" aria-hidden="true"></i>
            <p class="button-text">Excluir</p>
          </b-button>

          <b-button
            id="btnSave"
            @click.prevent="save()"
            :disabled="saveDisabled"
            v-has-permission.permission="['POS', 'AL', 'CA']"
            variant="muted"
          >
            <i class="fa fa-floppy-o fa-lg"></i>
            <p class="button-text">Salvar</p>
          </b-button>
        </b-button-group>
      </div>
    </div>
  </TemplatePopup>
</template>

<script>
import TemplatePopup from "../../../template/TemplatePopup";
import {
  polePath,
  hardwareKitSimplePath,
  folderSimplePath,
} from "../../../router/ApiPaths";
import PopupBase from "./PopupBase";

export default {
  name: "PoleMap",
  extends: PopupBase,
  data() {
    return {
      kitList: [],
      folderList: [],
    };
  },
  components: {
    TemplatePopup,
  },
  beforeMount() {
    this.entity = { kitId: "" };
  },
  mounted: function () {
    let path = polePath(this.folderId);
    this.init(path, false);

    this.getAllDirect(hardwareKitSimplePath).then((data) => {
      this.kitList = data;
    });
    this.getAllDirect(folderSimplePath).then((data) => {
      this.folderList = data;
    });
  },
  computed: {
    // missingFolder: function() {
    //   return !this.entity.folderId;
    // },
    saveDisabled: function() {
      return !this.isDirty;
    }
  },
};
</script>
