<template>
  <span
    :class="['badge', cssUsage, {'rounded-circle': statusResumed}]"
    v-b-tooltip.hover.bottom="'Status: ' + tooltipText"
  >
    {{ statusResumed ? result : result }}
  </span>
</template>

<script>
export default {
  name: "FolderStatus",
  props: ["status", "statusResumed"],
  computed: {
    result() {
      const statusMap = {
        C: this.statusResumed ? "C" : "Construindo",
        P: this.statusResumed ? "P" : "Pronto",
        R: this.statusResumed ? "P" : "Projeto",
      };

      return statusMap[this.status] || "";
    },
    cssUsage() {
      return {
        "badge-success": this.status === "P",
        "badge-primary": this.status === "C",
        "badge-danger": this.status === "R",
        "w-110": !this.statusResumed 
      };
    },
    tooltipText() {
      const statusMap = {
        C: "Construindo",
        P: "Pronto",
        R: "Projeto",
      };

      return statusMap[this.status] || "";
    },
  },
};
</script>

<style scoped>
.rounded-circle {
  border-radius: 50%;
  width: 2em; 
  height: 2em; 
  line-height: normal;
}
.w-110 {
  width: 110px;
}
</style>
