<template>
  <div class="secundaryTemplate">
    <div class="card-title margin-card">
      <nav aria-label="breadcrumbs mt-1">
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/map">Mapa</router-link>
          </li>
          <slot name="breadcrumb"></slot>
        </ul>
      </nav>
    </div>
    <div class="card-content px-3">
      <b-container v-if="showRequired">
        <p class="alert-message">
          <i class="fa fa-exclamation-circle" style="color: #dc3545;" aria-hidden="true"></i> 
          Campos em vermelho são obrigatórios.
        </p>
      </b-container>
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "TemplateCrud",
  props: ["showRequired"]
}
</script>

<style>
/* .nav-tabs{
  justify-content: center;
} */
</style>
