let containsModalsOpened = false;
import { hideElementsByClass, showElementsByClass } from './StyleChange'
import { closeAllPopupBus, setSelectMap } from "../MessageBus";

const closeResizeModal = () => {
    const overlay = document.getElementById('ResizeModal');
    overlay.style.display = "none"
    containsModalsOpened = false;
}

export const minimizeAll = () => {
    hideElementsByClass('overlay')

    const overlay = document.getElementById('ResizeModal');
    overlay.style.display = "block"

    containsModalsOpened = true;
}

export const showAll = () => {
    showElementsByClass('overlay')
    closeResizeModal()
    setSelectMap()
    
}

export const closeAll = () => {
    if (containsModalsOpened) {
        closeAllPopupBus()
        showAll()
    }
}