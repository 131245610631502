<template>
  <div>
    <b-table
      empty-text="Não existe registros para serem apresentados na lista."
      :items="list"
      :fields="fields"
      responsive
      show-empty
      small
      striped
    >
      <template #head(actions)>
        <b-button id="btnAdd" @click.prevent="add()" v-has-permission.permission="['CTT', 'CA']" size="sm" block variant="success">Adicionar</b-button>
      </template>
      <template #cell(actions)="row">
        <b-button :id="'btnEdit' + row.item.id" title="Editar" @click.prevent="edit(row.item.id)" v-has-permission.permission="['CTT', 'AL']" size="sm" variant="warning">
          <i class="fa fa-edit"></i>
        </b-button>

        <b-button :id="'btnDelete' + row.item.id" title="Excluir" @click.prevent="remove(row.item.id, row.index)" v-has-permission.permission="['CTT', 'EX']" size="sm" variant="danger">
          <i class="fa fa-trash-o"></i>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import BaseRequest from "../../../../request/request";
import { contactPath } from "../../../../router/ApiPaths";
import TabsBase from "./TabsBase.vue";
import { closePopupBus } from "../../../../components/MessageBus";

export default {
  name: "ContactTab",
  mixins: [BaseRequest],
  extends: TabsBase,
  props: ["equipmentId"],

  data() {
    return {
      list: [],
      fields: [],
    };
  },
  mounted() {
    this.type = 35;
    this.path = contactPath;
    this.load();
    this.fields = [
      {
        key: "name",
        label: "Nome",
      },
      {
        key: "type",
        label: "Tipo",
      },
      {
        key: "cellphone",
        label: "Celular",
      },
      {
        key: "lastContact",
        label: "Último contato",
      },
      {
        key: "actions",
        label: "Ações",
        class: "text-right width-100",
      },
    ];
  },
  methods: {
   
    exit() {
      closePopupBus();
    },
  },
};
</script>
