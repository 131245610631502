import { utils, writeFile } from 'xlsx';

const numberFormat = '0.00'

const formatNumber = (ws, col, length) => {
  for (let i = 1; i < length; i++) {
    ws['' + col + i].z = numberFormat
  }
}

export const writeCapexXls = (name, data) => {
  let rows = [
    ["Nome", "Tipo", "Fabricante", "Quantidade", "Preço unitário (R$)", "Preço total (R$)"]
  ]
  let total = 0

  data.map((obj) => {
    rows.push([obj.name, obj.type, obj.manufacturer, obj.size, obj.unitPrice, obj.price])
    total += obj.price
  })

  rows.push(["CAPEX Total", "", "", "", "", total])

  var ws = utils.aoa_to_sheet(rows);
  const wb = utils.book_new();

  formatNumber(ws, 'D', rows.length)
  formatNumber(ws, 'E', rows.length)
  formatNumber(ws, 'F', rows.length)

  ws['F' + (rows.length)].z = numberFormat

  ws.A1.t = {
    patternType: 'solid',
    fgColor: {
      theme: 8,
      tint: 0.3999755851924192,
      rgb: '9ED2E0'
    },
    bgColor: {
      indexed: 64
    }
  }

  // console.log(ws)

  //ws.D2.z = '0.00'		
  utils.book_append_sheet(wb, ws, name);

  /* generate an XLSX file */
  writeFile(wb, name + ".xlsx");
}


export const writeListXls = (header, list, name) => {
  let rows = header.concat(list)

  /*list.map((obj)=>{
  	rows.push([obj.name, obj.type, obj.manufacturer, obj.size, obj.unitPrice, obj.price])		
  })*/

  var ws = utils.aoa_to_sheet(rows);
  const wb = utils.book_new();

  utils.book_append_sheet(wb, ws, name);

  writeFile(wb, name + ".xlsx");
}
