<template>
  <TemplateCrud>
    <li slot="breadcrumb" class="breadcrumb-item active" aria-current="page">Relatório de Condomínios</li>
    <div slot="body">
      <i class="fa fa-exclamation-triangle text-warning"></i>
      Os dados são referentes apenas as pastas em que seu usuário está associado.
      <b-form-row class="my-2">
        <b-col cols="12" md="8">
          <b-form-input
            id="inpSearch"
            v-model="search"
            size="sm"
            placeholder="Digite o nome, endereço ou status que deseja buscar"
            debounce="1000"
          ></b-form-input>
        </b-col>
        <b-col cols="6" md="2">
          <b-form-select v-model="folderId" size="sm" placeholder="Selecione a pasta" class="w-100">
            <b-form-select-option value="" selected>Todas as pastas</b-form-select-option>
            <b-form-select-option v-for="entityList in folderList" :value="entityList.id" :key="entityList.id">
              {{ entityList.name }}
            </b-form-select-option>          
          </b-form-select>
        </b-col>
        <b-col cols="3" md="1">
          <b-button size="sm" block variant="danger" @click.prevent="openDownloadDialog(true)">
            <i class="fa fa-file-pdf-o mr-1" aria-hidden="true"></i>
            PDF
          </b-button>
        </b-col>
        <b-col cols="3" md="1">
          <b-button size="sm" block variant="success" @click.prevent="openDownloadDialog(false)">
            <i class="fa fa-file-excel-o mr-1" aria-hidden="true"></i>
            XLS
          </b-button>
        </b-col>
      </b-form-row>

      <b-table
        empty-text="Não existe registros para serem apresentados na lista."
        :items="list"
        :fields="fields"
        responsive
        show-empty
        small
        striped
        class="table-fixed"
      >
        <template #cell(name)="data">
          <span class="wrap-column">{{ data.item.name }}</span>
        </template>
        <template #cell(folderName)="data">
          <span class="fixed-width-column">{{ data.item.folderName }}</span>
        </template>
        <template #cell(address)="data">
          <span class="address-column wrap-column">{{ data.item.address }}</span>
        </template>
        <template #cell(identifier)="data">
          <span class="fixed-width-column">{{ data.item.identifier }}</span>
        </template>
        <template #cell(status)="data">
          <span class="fixed-width-column">{{ data.item.status }}</span>
        </template>
        <template #cell(eqName)="data">
          <span class="fixed-width-column">{{ data.item.eqName }}</span>
        </template>
        <template #cell(doorName)="data">
          <span class="fixed-width-column">{{ data.item.doorName }}</span>
        </template>
      </b-table>
      <div class="d-flex justify-content-between align-items-center pb-3">
        <div class="width-300">
          <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="7"
            label="Registros"
          >
            <b-form-select v-model="itemsPerPage">
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <b-pagination
          class="mb-0"
          v-model="currentPage"
          :total-rows="count"
          :per-page="itemsPerPage"
          size="md"
        ></b-pagination>
      </div>
    </div>
  </TemplateCrud>
</template>

<script>
import TemplateCrud from "../../../../template/TemplateCrud";
import Request from "../../../../request/request";
import { verticalCondominiumReportPath, folderSimplePath } from "../../../../router/ApiPaths";
import PDF from "../../../../components/extra/PDF";
import { writeListXls } from "../../../../components/extra/Xlsx";

export default {
  name: "CondominiumReport",
  extends: Request,
  components: {
    TemplateCrud
  },
  data() {
    return {
      count: 0,
      list: [],
      search: "",
      currentPage: 1,
      itemsPerPage: 50,
      folderList: {},
      folderId: '',
      fields: [
        { key: "name", label: "Nome" },
        { key: "folderName", label: "Pasta" },
        { key: "address", label: "Endereço" },
        { key: "identifier", label: "Identificador" },
        { key: "condominiumType", label: "Tipo" },
      ],
    };
  },
  mounted() {
    this.find();
    this.baseRequest("get", folderSimplePath).then((data) => {
      this.folderList = data;
    });
  },
  methods: {
    async find(getAll = false) {
      let url = verticalCondominiumReportPath;

      if (getAll) {
        url += "?all=true";
      } else {
        url += `?limit=${this.itemsPerPage}&offset=${(this.currentPage - 1) * this.itemsPerPage}`;
      }

      if (this.search) url += "&search=" + this.search;
      if (this.folderId) url += "&folderId=" + this.folderId;

      const data = await this.get(url);

      if (!getAll) {
        this.count = data.count;
        this.list = data.items;
      } else {
        return data.items;
      }
    },
    openDownloadDialog(isPdf) {
      this.$confirmDialog.openWithCustomButtons(
        "Escolha a opção para download:",
        [
          { text: "Apenas esta página", variant: "primary", cb: () => this.exportFile(isPdf, true) },
          { text: "Lista completa", variant: "success", cb: () => this.exportFile(isPdf, false) }
        ], true
      );
    },
    async exportFile(pdf, visibleOnly = true) {
      const fileName = "Relatório Condomínios";

      let items;
      if (visibleOnly) {
        items = this.list;
      } else {
        items = await this.find(true);
      }

      const header = ["Nome", "Endereço", "Identificador", "Tipo"];

      const rows = items.map(item => [
        item.name,
        item.address,
        item.identifier,
        item.condominiumType
      ]);

      if (pdf) {
        PDF.reportList(header, rows, fileName);
      } else {
        writeListXls([header], rows, fileName);
      }
    }
  },
  watch: {
    currentPage() {
      this.find();
    },
    itemsPerPage() {
      this.find();
    },
    search() {
      this.find();
    },
    folderId() {
      this.find();
    }
  },
};
</script>

<style scoped>
.table-fixed {
  table-layout: fixed;
  width: 100%;
}

.wrap-column {
  word-break: break-all;
  white-space: normal;
}

.fixed-width-column {
  width: 150px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>