<template>
  <div id="app">
    <transition name="fade">
      <div class="loading text-center" v-if="this.$store.getters.isLoading">
        <div class="spinner"></div>
      </div>
    </transition>
    <Header />
    <Message />
    <ModalCounter/>
    <MessagePayment />
    <router-view />
    <Popup-container />
    <ResizeModal />
    <Dialog ref="confirmDialog" />
    <Whatsapp/>
    <TipScreen/>
  </div>
</template>

<script>
import Vue from "vue";
import Header from "./components/header/Header";
import Message from "./components/Message";
import MessagePayment from "./components/MessagePayment";
import PopupContainer from "./components/PopupContainer";
import ResizeModal from "./view/general/ResizeModal";
import Dialog from "./components/Dialog";
import Whatsapp from "./components/extra/WhatsappButton";
// import WebChat from "./components/extra/WebChat";
import ModalCounter from "./components/extra/ModalCounter.vue";
import TipScreen from "./components/TipScreen.vue";

export default {
  name: "App",
  components: {
    Header,
    Message,
    MessagePayment,
    PopupContainer,
    ResizeModal,
    Dialog,
    Whatsapp,
    TipScreen,
    ModalCounter
  },
  mounted() {
    Vue.prototype.$confirmDialog = this.$refs.confirmDialog;
  },
};
</script>

<style lang="sass">
@import "jointjs/dist/joint.css"
@import "../public/static/sass/_variables.scss"

$brand-color-primary: #ff5800 !default
$color-transparent: transparent !default
$color-orange-50: #ff945b !default
$color-white: #ffffff !default
$color-red: #e74c3c !default
$color-red-200: #af1a0a !default
$color-yellow: #ff9800 !default
$color-yellow-200: #de8400 !default
$color-blue: #341f97 !default
$color-blue-100: #2980b9 !default
$color-grey: #535a5a !default
$color-grey-90: rgba(255, 255, 255, 0.9) !default
$color-grey-50: rgba(255,255,255,0.5) !default
$color-grey-100: #9e9e9e !default
$color-grey-200: #4c4c4c !default
$color-black: #0c0c0c !default
$color-black-50: rgba(0, 0, 0, 0.5) !default
$color-green: #2ecc71 !default
$color-green-200: #009933 !default
$color-red: #db2511 !default
$pink: #F93B69
$green: #B0E8E2
$orange: #EB8B6E
$dark-grey: #808080

.secundaryTemplate
  position: relative
  z-index: 1
  background-color: $color-white

.width-30
  width: 30px

.width-50
  width: 50px

.width-100
  width: 100px

.width-110
  width: 110px

.width-120
  width: 120px

.width-130
  width: 130px

.width-140
  width: 140px

.width-150
  width: 150px

.width-160
  width: 160px

.width-170
  width: 170px

.width-180
  width: 180px

.width-300
  width: 300px

html
  overflow-y: auto !important

body
  height: 100vh
  font-family: 'Lato', sans-serif !important

a:hover
  text-decoration: none

.c-pointer
  cursor: pointer

.c-pointer:hover 
  transform: scale(1.2)

.c-default
  cursor: default

.a-sidebar
  color: #ffff !important
  &:hover
    color: #f56036
    text-decoration: none

.alert-message
  color: #b70606
  font-size: 14px

.v-label
  font-weight: 500
  font-size: 16px

.color-orange
  color: $brand-color-primary

.color-grey
  color: $color-grey

.color-black
  color: $color-black

.color-white
  color: $color-white

.help
  color: $color-red
  font-size: 12px

.usage
  color: $color-white !important

.msg-color
  color: $brand-color-primary

.border5
  border-radius: 5px !important

.form-group
  margin-bottom: 0.3rem !important

.v--modal
  padding: 10px !important

.v--modal-overlay
  z-index: 1002 !important

.v--modal-overlay
  background-color: rgba(0, 0, 0, 0.45)

.w-15
  width: 15% !important

.w-table-td-20
  width: 20px

.fontSize15
  font-size: 15px

.fontSize20
  font-size: 20px

.cursor
  cursor: pointer

.table-wrapper
  overflow-x: auto

.button-map
  border-radius: 5px !important

.font-size-13
  font-size: 13px

.font-size-15
  font-size: 15px

.buttons_bar
  float: left

.btn-transparent
  background-color: transparent

.icon-question
  color: #007bff

.popUpBody
  padding: 5px

.tl-status-2
  color: #333

.tl-status-3
  color: $color-grey-100

.ml-25
  margin-left: 25px

.list-msg
  border: solid 1px #e9ecef

.logback
  z-index: 1010 !important

.register-form
  margin-top: 100px

.mr5
  margin-right: 5px

.mt-26
  margin-top: 26px

.mr-n14
  margin-right: -14px!important

.pl-17
  padding-left: 17px!important

.mt10p
  margin-top: 10px

.pln-11
  margin-top: -11px

.pl-5px
  margin-left: 5px

@media only screen and (max-width: 770px)
  .btnStyle
    display: block
  .div-msg
    display: block
    position: absolute
  .btn-list
    display: block

// .loading
//   width: 100%
//   height: 100%
//   position: fixed
//   background-color: #131313
//   top: 0
//   left: 0
//   bottom: 0
//   right: 0
//   z-index: 100000
//   opacity: 0.5
//   cursor: wait

.loading
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  position: fixed
  background-color: rgba(19, 19, 19, 0.5) /* usando rgba para o fundo semi-transparente */
  top: 0
  left: 0
  z-index: 100000
  cursor: wait

.fa-spinner
  font-size: 3em
  color: #fff

.title-label
  color: $brand-color-primary
  font-weight: 500
  font-size: 14px
  padding-left: 0px

.card-component
  margin-left: -15px
  margin-top: 10px
  margin-bottom: 10px
  border-radius: 5px
  border: 0px
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14)

.button-default
  font-size: 15px
  background-color: transparent
  border-color: transparent
  &:hover
    color: $color-white

.add
  @extend .button-default
  color: $color-green
  &:hover
    background-color: $color-green
    border-color: $color-green

.addFolder
  @extend .button-default
  color: $color-green
  &:hover
    color: $color-green-200

.edit
  @extend .button-default
  color: $color-yellow
  &:hover
    background-color: $color-yellow
    border-color: $color-yellow

.editFolder
  @extend .button-default
  color: $color-yellow
  &:hover
    color: $color-yellow-200

.edit
  @extend .button-default
  color: $color-yellow
  &:hover
    background-color: $color-yellow
    border-color: $color-yellow

.deleteFolder
  @extend .button-default
  color: $color-red
  &:hover
    color: $color-red-200

.mapa
  @extend .button-default
  color: $color-blue
  &:hover
    background-color: $color-blue
    border-color: $color-blue

.mapa_
  @extend .button-default
  cursor: pointer
  color: #8e44ad
  &:hover
    color: #572b69

.delete_
  @extend .button-default
  cursor: pointer
  color: #e74c3c
  &:hover
    color: #ce2f1f

.edit_
  @extend .button-default
  color: $color-yellow
  cursor: pointer
  &:hover
    color: #ca7800

.add_
  @extend .button-default
  color: #2ecc71
  cursor: pointer
  &:hover
    color: #27ae60

.message-delete
  @extend .button-default
  color: rgba(0,0,0,.2)
  &:hover
    color: #e74c3c

.delete
  @extend .button-default
  color: $color-red
  &:hover
    background-color: $color-red
    border-color: $color-red

.report
  @extend .button-default
  color: $color-blue-100
  &:hover
    background-color: $color-blue-100
    border-color: $color-blue-100

.button-delete-border
  border-radius: 30px
  font-size: 13px

.icon-expand
  position: inherit
  float: right
  color: $brand-color-primary

.button-disabled
  background: transparent
  border: 0

@media only screen and (max-width: 770px)
  .w-30
    width: 30%

.menu-label
  margin-bottom: 10px
  margin-top: 10px

.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0

.is-vcentered
  display: flex
  flex-wrap: wrap
  align-content: center
  align-items: center

.table
  td, th
    vertical-align: middle

.animated
  -webkit-animation-duration: 0.5s
  -webkit-animation-fill-mode: both
  animation-duration: 0.5s
  animation-fill-mode: both

.buttonLegend
  background-color: $color-grey
  color: $color-white
  &:hover
    background-color: $brand-color-primary
    color: $color-white

.card-value
  position: inherit
  float: right

.btn-border-white
  border-style: solid !important
  border-color: $color-white !important

button[disabled]
  color: $color-grey-100
  cursor: context-menu
  &:hover
    color: $color-grey-100

.btn-light
  color: $color-black
  background-color: $color-white
  border-color: $color-grey-100

.paragraph-15-weight300
  font-size: 15px
  font-weight: 300

.paragraph-20-weight300
  font-size: 20px
  font-weight: 300

.useful_off
  @extend .usage
  background: #d3d3d3 !important
  color: #5a5959 !important

.useful_0_25
  @extend .usage
  background-color: $color-green-200 !important

.useful_25_50
  @extend .usage
  background-color: #ffcc00ff !important
  color: #000000 !important

.useful_50_75
  @extend .usage
  background-color: #ff6600ff !important

.useful_75_100
  @extend .usage
  background-color: $color-red !important

.vc-compact
  box-shadow: none !important
  width: 100% !important

.menu-list
  max-height: 400px
  overflow-y: scroll

.font-table
  font-weight: 400
  font-size: 14px
  color: $color-grey-200

.diagram
  background-color: $color-blue-100
  border-color: $color-blue-100

.badge-radius
  border-radius: 50%
  width: 25px

.map
  background-color: $brand-color-primary
  border-color: $brand-color-primary

.scroll-list
  height: 89vh
  overflow-y: auto

.message-text
  height: 80vh
  width: 100%
  overflow: auto

.div-msg
  border-radius: 5px
  padding-top: 10px
  background-color: $color-white

.btn-list
  width: 100%
  border: $color-white
  border-bottom: 1px solid #e9ecef
  border-radius: 0
  text-align: left

.btnStyle
  float: right
  margin: -10px
  margin-right: 20px
  font-size: 20px
  padding: 5px

.date
  color: $color-grey-200
  float: left

.list
  max-height: 400px
  overflow-y: scroll

.listButton
  color: $color-black
  overflow-y: hidden !important
  overflow-x: hidden
  font-weight: 400
  font-size: 14px
  &:active
    color: $brand-color-primary !important

.message-body
  margin-top: -16px!important
  background-color: #ececec47
  height: -webkit-fill-available

.fw-15
  font-size: 15px !important

.title-msg
  color: $brand-color-primary
  font-size: 18px

.close-btn
  background-color: transparent
  border: 0
  -webkit-appearance: none
  float: right
  font-size: 1.5rem
  font-weight: 700
  line-height: 1
  color: $color-black
  text-shadow: 0 1px 0 $color-white
  opacity: 0.5
  cursor: pointer

.control-spacing
  padding-bottom: 5px
  padding-top: 5px

.btnStylePosition
  float: right
  margin: -10px
  font-size: 20px
  padding: 5px

.btnStyle-list
  margin: -10px
  margin-top: 0px
  padding: 5px

.timeline
  ul
    padding: 0
    margin: 0
    list-style: none
    position: relative

  li div
    display: inline-block
    margin: 0
    vertical-align: top

  .bullet
    width: 1em
    height: 1em
    box-sizing: border-box
    border-radius: 50%
    z-index: 1
    margin-right: 0.5em
    margin-top: 10px

    &.pink
      border: 2px solid $pink

    &.green
      border: 2px solid $green

    &.orange
      border: 2px solid $orange

  .time
    width: 86%
    font-size: 1.05em
    padding-top: 0.25em

  h4
    margin: 0
    font-size: 0.7em
    font-weight: 400
    color: $dark-grey

.form-typography
  font-size: 13px
  font-weight: 400

.number-msg
  color: $dark-grey
  font-weight: 400

.notification
  position: absolute
  width: 5px
  height: 5px
  border-radius: 50%
  top: 2px
  right: 2px
  background: $pink

.login-page
  width: 360px
  padding: 0
  margin: auto

.version
  padding: 5px
  color: $color-grey

.legend
  background-color: #343a40
  border-radius: 4px
  font-size: 13px
  bottom: 9px
  right: 108px
  padding: 10px
  position: absolute
  .label
    color: $color-black
  h6
    color: $color-white

.colorSelected
  background-color: $brand-color-primary
  color: $color-white

.form-login
  position: relative
  z-index: 1
  background: $color-white
  height: 100%
  padding: 45px
  text-align: center

@media only screen and (max-width: 414px)
  .form-login
    width: 100%

.forgot
  padding: 5px
  color: #261a16
  font-weight: 600

.version
  padding: 5px
  color: $color-grey

.btn-base
  background-color: $brand-color-primary
  border: none
  border-radius: 5px

.input-login
  width: 100%
  height: 3rem
  font-size: 1rem
  border: 0
  border-bottom: 1.2px solid $color-grey-100
  outline: none
  transition: 0.2s ease-in-out
  box-sizing: border-box

.th-line
  font-weight: 500

.gif-img
  padding-top: 20%

.toolbar-design
  width: 54px
  position: absolute
  top: 110px
  height: 92%

.idTl
  font-size: 15px
  font-weight: 500

.inf-component
  background-color: transparent
  color: #0066b2
  width: 30px
  border: none
  cursor: pointer

.inf-component:hover
  color: #00adee

@media only screen and (max-width: 770px)
  .button-text
    display: none
  
  .toolbar-design
    top: 140px

.selected
  background-color: #0188F8 !important
  
.controls-zoom 
  bottom: 15px
  right: 10px

.btn-outline-orange 
  color: #ff6600ff !important;
  border-color: #ff6600ff !important;
  &:hover 
    background-color: #ff6600ff !important;
    color: #fff !important;

</style>

<style>
.pulse {
  animation: pulse 0.7s infinite;
  margin: 0 auto;
  display: table;
  margin-top: 50px;
  animation-direction: alternate;
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -webkit-filter: brightness(100%);
  }
  100% {
    -webkit-transform: scale(1.1);
    -webkit-filter: brightness(200%);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    filter: brightness(100%);
  }
  100% {
    transform: scale(1.1);
    filter: brightness(200%);
  }
}

.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #f2f2f2;
  transform-origin: top;
  display: grid;
  animation: spinner-3vgb5t 1.2s infinite linear;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  background: #007bff;
  border-radius: 50%;
  transform-origin: top;
  animation: inherit;
  animation-name: spinner-k94uub;
}

.spinner::after {
  background: #0108f8;
  --s: 180deg;
}

@keyframes spinner-3vgb5t {
  0%,
  20% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-k94uub {
  50% {
    transform: rotate(var(--s, 90deg));
  }

  100% {
    transform: rotate(0);
  }
}
</style>
