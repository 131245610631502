import gmapsInit from "./GmapsUtil.js";
let google;
let geocoderService;

export default {
  async mounted() {
    try {
      await gmapsInit();
      if (window.google && window.google.maps) {
        geocoderService = new window.google.maps.Geocoder();
        google = window.google;
      } else {
        console.warn("Google Maps API not loaded.");
      }
    } catch (error) {
      console.error("Error initializing Google Maps API:", error);
    }
  },
  methods: {
    getAddressPoint(point) {
     return getAddressPointExported(point)
    },
    searchAddressByText(term, bbox) {
      return new Promise(resolve => {
        if (!google || !geocoderService) {
          console.warn("Google Maps not available.");
          resolve([]);
          return;
        }

        const opt = { address: term, region: "BR" };

        if (bbox)
          opt.bounds = new google.maps.LatLngBounds(
            new window.google.maps.LatLng(bbox[1], bbox[0]),
            new window.google.maps.LatLng(bbox[3], bbox[2])
          );

        geocoderService.geocode(opt, (results, status) => {
          let addressArray = [];
          if (status == google.maps.GeocoderStatus.OK) {
            for (let i = 0; i < results.length; i++) {
              addressArray.push({
                name: results[i].formatted_address,
                geometry: {
                  type: "Point",
                  coordinates: [
                    results[i].geometry.location.lng(),
                    results[i].geometry.location.lat()
                  ]
                }
              });
            }
          }
          resolve(addressArray);
        });
      });
    }
  }
};
export const getAddressPointExported = (point) => {
  return new Promise((resolve, reject) => {
    try {
      const latlng = new google.maps.LatLng(point[1], point[0]);
      geocoderService.geocode({ location: latlng }, function(results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results && results.length) {
            resolve(results);
          } else {
            resolve([]);
            console.warn("No results found for the given point.");
          }
        } else {
          console.error("Geocoding failed due to: " + status);
          resolve([]);
        }
      });
    } catch (error) {
      console.error("Failed to execute geocode due to API configuration:", error);
      resolve([]);
    }
    
  });
}

export const getRouteToPoints = (startPoint, endPoint) => {
  return new Promise(resolve => {
    if (!google || !window.google.maps) {
      console.warn("Google Maps not available for getRouteToPoints.");
      resolve(null);
      return;
    }

    let directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: new window.google.maps.LatLng(startPoint[1], startPoint[0]),
        destination: new window.google.maps.LatLng(endPoint[1], endPoint[0]),
        travelMode: "WALKING",
        unitSystem: google.maps.UnitSystem.METRIC
      },
      function(response, status) {
        if (status === "OK") {
          if (response.routes) {
            var line = {
              coordinates: [],
              distance: 0
            };

            var coordinatesRoute = response.routes[0].overview_path;

            line.coordinates.push([startPoint[0], startPoint[1]]);

            for (var i = 0; i < coordinatesRoute.length; i++) {
              line.coordinates.push([
                coordinatesRoute[i].lng(),
                coordinatesRoute[i].lat()
              ]);
            }

            line.coordinates.push([endPoint[0], endPoint[1]]);

            if (response.routes[0].legs[0]) {
              line.distance = response.routes[0].legs[0].distance.value;
            }

            resolve(line);
          }
        } else {
          console.log("Não foi possível buscar as rotas");
        }
      }
    );
  });
};
