<template>
  <div class>
    <input
      ref="inputComboAutocomplete"
      type="text"
      class="form-control"
      :class="{ 'is-invalid': missingInputExpression && !isDisabled}"
      placeholder="Procurar..."
      autocomplete="off"
      v-model="expression"
      v-bind:maxlength="maxlength"
      :disabled="isDisabled"
      @input="onChange"
      @click="setVisible"
      :onblur="updateData()"
    />

    <transition name="fade">
      <div class="search-autocomplete" v-if="visible">
        <div
          class="list-group list-group-sm list-combo shadow"
          v-click-outside="hideVisible"
          :style="getWidthInput"
        >
          <button
            v-for="(item, index) in filteredItems"
            :key="index"
            type="button"
            class="list-group-item list-group-item-action py-1"
            @click.prevent="setSelected(item)"
          >
            <div>
              <div class>
                <span class="font-weight-bold">{{item[fieldSearch]}}</span>
              </div>
            </div>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from "vue";
import vClickOutside from "v-click-outside";

Vue.use(vClickOutside);

export default {
  name: "ComboAutocomplete",
  props: ["listCombo", "fieldSearch", "missingInputExpression", "isDisabled", "maxlength"],
  data() {
    return {
      expression: "",
      visible: false,
      itemSelected: null
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    getWidthInput() {
      return {
        width: this.$refs.inputComboAutocomplete.clientWidth + "px"
      };
    },
    filteredItems() {
      return this.listCombo.filter(item => {
        if (this.expression) {
          return (
            item[this.fieldSearch]
              .toLowerCase()
              .indexOf(this.expression.toLowerCase()) > -1
          );
        } else {
          return item;
        }
      });
    }
  },
  methods: {
    onChange() {
      this.visible = true;
      this.itemSelected = null;
    },
    setSelected(item) {
      this.expression = item[this.fieldSearch];
      this.itemSelected = item.id;
      this.hideVisible();
    },
    setVisible() {
      this.visible = !this.visible;
    },
    hideVisible() {
      this.visible = false;
    },
    updateData(){
      this.$emit('inputChange', {[this.fieldSearch]: this.expression, id: this.itemSelected})
    }
  }
};
</script>

<style lang="sass" scoped>
.list-combo
  position: fixed
  max-height: 58vh
  overflow-y: auto
  z-index: 3
</style>
